/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*@import "root";*/

*, *::before, *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
    width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

dfn {
    font-style: italic;
}

b, strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: #22b970;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:hover {
    color: #167849;
    text-decoration: underline;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

pre, code, kbd, samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button, input {
    overflow: visible;
}

button, select {
    text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"], input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

h1, .h1 {
    font-size: 2.1875rem;
}

h2, .h2 {
    font-size: 1.75rem;
}

h3, .h3 {
    font-size: 1.53125rem;
}

h4, .h4 {
    font-size: 1.3125rem;
}

h5, .h5 {
    font-size: 1.09375rem;
}

h6, .h6 {
    font-size: 0.875rem;
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small, .small {
    font-size: 80%;
    font-weight: 400;
}

mark, .mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer::before {
    content: "\2014 \00A0";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: none;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: none;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col, .no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
}

.order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
}

.order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
}

.order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

.order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}

.order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
}

.order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
}

.order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
}

.order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
}

.order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
}

.order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
}

.order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
}

.order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
}

.order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
}

.order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-sm-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-sm-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-sm-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-sm-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
    .order-sm-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }
    .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }
    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
    .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
    .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }
    .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }
    .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }
    .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }
    .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
    .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }
    .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }
    .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }
    .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-sm-0 {
        margin-left: 0;
    }
    .offset-sm-1 {
        margin-left: 8.33333%;
    }
    .offset-sm-2 {
        margin-left: 16.66667%;
    }
    .offset-sm-3 {
        margin-left: 25%;
    }
    .offset-sm-4 {
        margin-left: 33.33333%;
    }
    .offset-sm-5 {
        margin-left: 41.66667%;
    }
    .offset-sm-6 {
        margin-left: 50%;
    }
    .offset-sm-7 {
        margin-left: 58.33333%;
    }
    .offset-sm-8 {
        margin-left: 66.66667%;
    }
    .offset-sm-9 {
        margin-left: 75%;
    }
    .offset-sm-10 {
        margin-left: 83.33333%;
    }
    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-md-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
    .order-md-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }
    .order-md-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }
    .order-md-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .order-md-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
    .order-md-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
    .order-md-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }
    .order-md-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }
    .order-md-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }
    .order-md-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }
    .order-md-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
    .order-md-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }
    .order-md-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }
    .order-md-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }
    .order-md-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-md-0 {
        margin-left: 0;
    }
    .offset-md-1 {
        margin-left: 8.33333%;
    }
    .offset-md-2 {
        margin-left: 16.66667%;
    }
    .offset-md-3 {
        margin-left: 25%;
    }
    .offset-md-4 {
        margin-left: 33.33333%;
    }
    .offset-md-5 {
        margin-left: 41.66667%;
    }
    .offset-md-6 {
        margin-left: 50%;
    }
    .offset-md-7 {
        margin-left: 58.33333%;
    }
    .offset-md-8 {
        margin-left: 66.66667%;
    }
    .offset-md-9 {
        margin-left: 75%;
    }
    .offset-md-10 {
        margin-left: 83.33333%;
    }
    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-lg-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
    .order-lg-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }
    .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }
    .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
    .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
    .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }
    .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }
    .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }
    .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }
    .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
    .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }
    .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }
    .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }
    .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-lg-0 {
        margin-left: 0;
    }
    .offset-lg-1 {
        margin-left: 8.33333%;
    }
    .offset-lg-2 {
        margin-left: 16.66667%;
    }
    .offset-lg-3 {
        margin-left: 25%;
    }
    .offset-lg-4 {
        margin-left: 33.33333%;
    }
    .offset-lg-5 {
        margin-left: 41.66667%;
    }
    .offset-lg-6 {
        margin-left: 50%;
    }
    .offset-lg-7 {
        margin-left: 58.33333%;
    }
    .offset-lg-8 {
        margin-left: 66.66667%;
    }
    .offset-lg-9 {
        margin-left: 75%;
    }
    .offset-lg-10 {
        margin-left: 83.33333%;
    }
    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-xl-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
    .order-xl-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }
    .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }
    .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
    .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
    .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }
    .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }
    .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }
    .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }
    .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
    .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }
    .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }
    .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }
    .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-xl-0 {
        margin-left: 0;
    }
    .offset-xl-1 {
        margin-left: 8.33333%;
    }
    .offset-xl-2 {
        margin-left: 16.66667%;
    }
    .offset-xl-3 {
        margin-left: 25%;
    }
    .offset-xl-4 {
        margin-left: 33.33333%;
    }
    .offset-xl-5 {
        margin-left: 41.66667%;
    }
    .offset-xl-6 {
        margin-left: 50%;
    }
    .offset-xl-7 {
        margin-left: 58.33333%;
    }
    .offset-xl-8 {
        margin-left: 66.66667%;
    }
    .offset-xl-9 {
        margin-left: 75%;
    }
    .offset-xl-10 {
        margin-left: 83.33333%;
    }
    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.table th, .table td {
    padding: 0.75rem;
    vertical-align: center;
    border-top: 1px solid #dee2e6;
    vertical-align: middle;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table .table {
    background-color: #fff;
}

.table-sm th, .table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary, .table-primary>th, .table-primary>td {
    background-color: #b7daff;
}

.table-hover .table-primary:hover {
    background-color: #9ecdff;
}

.table-hover .table-primary:hover>td, .table-hover .table-primary:hover>th {
    background-color: #9ecdff;
}

.table-secondary, .table-secondary>th, .table-secondary>td {
    background-color: #d5d8da;
}

.table-hover .table-secondary:hover {
    background-color: #c7cbce;
}

.table-hover .table-secondary:hover>td, .table-hover .table-secondary:hover>th {
    background-color: #c7cbce;
}

.table-success, .table-success>th, .table-success>td {
    background-color: #c1ebd6;
}

.table-hover .table-success:hover {
    background-color: #aee5c9;
}

.table-hover .table-success:hover>td, .table-hover .table-success:hover>th {
    background-color: #aee5c9;
}

.table-info, .table-info>th, .table-info>td {
    background-color: #bee4eb;
}

.table-hover .table-info:hover {
    background-color: #abdce5;
}

.table-hover .table-info:hover>td, .table-hover .table-info:hover>th {
    background-color: #abdce5;
}

.table-warning, .table-warning>th, .table-warning>td {
    background-color: #ffedb9;
}

.table-hover .table-warning:hover {
    background-color: #ffe6a0;
}

.table-hover .table-warning:hover>td, .table-hover .table-warning:hover>th {
    background-color: #ffe6a0;
}

.table-danger, .table-danger>th, .table-danger>td {
    background-color: #f7d6d3;
}

.table-hover .table-danger:hover {
    background-color: #f3c2bd;
}

.table-hover .table-danger:hover>td, .table-hover .table-danger:hover>th {
    background-color: #f3c2bd;
}

.table-light, .table-light>th, .table-light>td {
    background-color: #fdfdfd;
}

.table-hover .table-light:hover {
    background-color: #f0f0f0;
}

.table-hover .table-light:hover>td, .table-hover .table-light:hover>th {
    background-color: #f0f0f0;
}

.table-dark, .table-dark>th, .table-dark>td {
    background-color: #c6c7c9;
}

.table-hover .table-dark:hover {
    background-color: #b9babd;
}

.table-hover .table-dark:hover>td, .table-hover .table-dark:hover>th {
    background-color: #b9babd;
}

.table-active, .table-active>th, .table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td, .table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.table-dark {
    color: #fff;
    background-color: #212529;
}

.table-dark th, .table-dark td, .table-dark thead th {
    border-color: #32383e;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        /* overflow-x: auto; */
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        /* overflow-x: auto; */
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        /* overflow-x: auto; */
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        /* overflow-x: auto; */
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    /* overflow-x: auto; */
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive>.table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 13px;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    border: 1px solid #824e84;
    border-radius: none;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    border-color: #75e6af;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
}

.form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
    opacity: 1;
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
    opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

select.form-control:focus::-ms-value {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
}

.form-control-file, .form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.76563rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.64844rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: none;
}

.form-control-lg {
    height: calc(2.64063rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: none;
}

select.form-control[size], select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
    position: relative;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col, .form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
    color: #6c757d;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #22b970;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(34, 185, 112, 0.9);
    border-radius: none;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
    border-color: #22b970;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #22b970;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
}

.was-validated .form-control:valid~.valid-feedback, .was-validated .form-control:valid~.valid-tooltip, .form-control.is-valid~.valid-feedback, .form-control.is-valid~.valid-tooltip, .was-validated .custom-select:valid~.valid-feedback, .was-validated .custom-select:valid~.valid-tooltip, .custom-select.is-valid~.valid-feedback, .custom-select.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-control-file:valid~.valid-feedback, .was-validated .form-control-file:valid~.valid-tooltip, .form-control-file.is-valid~.valid-feedback, .form-control-file.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-check-input:valid~.form-check-label, .form-check-input.is-valid~.form-check-label {
    color: #22b970;
}

.was-validated .form-check-input:valid~.valid-feedback, .was-validated .form-check-input:valid~.valid-tooltip, .form-check-input.is-valid~.valid-feedback, .form-check-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label, .custom-control-input.is-valid~.custom-control-label {
    color: #22b970;
}

.was-validated .custom-control-input:valid~.custom-control-label::before, .custom-control-input.is-valid~.custom-control-label::before {
    background-color: #75e6af;
}

.was-validated .custom-control-input:valid~.valid-feedback, .was-validated .custom-control-input:valid~.valid-tooltip, .custom-control-input.is-valid~.valid-feedback, .custom-control-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before, .custom-control-input.is-valid:checked~.custom-control-label::before {
    background-color: #34da8a;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before, .custom-control-input.is-valid:focus~.custom-control-label::before {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
}

.was-validated .custom-file-input:valid~.custom-file-label, .custom-file-input.is-valid~.custom-file-label {
    border-color: #22b970;
}

.was-validated .custom-file-input:valid~.custom-file-label::after, .custom-file-input.is-valid~.custom-file-label::after {
    border-color: inherit;
}

.was-validated .custom-file-input:valid~.valid-feedback, .was-validated .custom-file-input:valid~.valid-tooltip, .custom-file-input.is-valid~.valid-feedback, .custom-file-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label, .custom-file-input.is-valid:focus~.custom-file-label {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e36e65;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(227, 110, 101, 0.9);
    border-radius: none;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
    border-color: #e36e65;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #e36e65;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.25);
}

.was-validated .form-control:invalid~.invalid-feedback, .was-validated .form-control:invalid~.invalid-tooltip, .form-control.is-invalid~.invalid-feedback, .form-control.is-invalid~.invalid-tooltip, .was-validated .custom-select:invalid~.invalid-feedback, .was-validated .custom-select:invalid~.invalid-tooltip, .custom-select.is-invalid~.invalid-feedback, .custom-select.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-control-file:invalid~.invalid-feedback, .was-validated .form-control-file:invalid~.invalid-tooltip, .form-control-file.is-invalid~.invalid-feedback, .form-control-file.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-check-input:invalid~.form-check-label, .form-check-input.is-invalid~.form-check-label {
    color: #e36e65;
}

.was-validated .form-check-input:invalid~.invalid-feedback, .was-validated .form-check-input:invalid~.invalid-tooltip, .form-check-input.is-invalid~.invalid-feedback, .form-check-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label, .custom-control-input.is-invalid~.custom-control-label {
    color: #e36e65;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before, .custom-control-input.is-invalid~.custom-control-label::before {
    background-color: #f7d4d1;
}

.was-validated .custom-control-input:invalid~.invalid-feedback, .was-validated .custom-control-input:invalid~.invalid-tooltip, .custom-control-input.is-invalid~.invalid-feedback, .custom-control-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before, .custom-control-input.is-invalid:checked~.custom-control-label::before {
    background-color: #eb9790;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before, .custom-control-input.is-invalid:focus~.custom-control-label::before {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(227, 110, 101, 0.25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(227, 110, 101, 0.25);
}

.was-validated .custom-file-input:invalid~.custom-file-label, .custom-file-input.is-invalid~.custom-file-label {
    border-color: #e36e65;
}

.was-validated .custom-file-input:invalid~.custom-file-label::after, .custom-file-input.is-invalid~.custom-file-label::after {
    border-color: inherit;
}

.was-validated .custom-file-input:invalid~.invalid-feedback, .was-validated .custom-file-input:invalid~.invalid-tooltip, .custom-file-input.is-invalid~.invalid-feedback, .custom-file-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label, .custom-file-input.is-invalid:focus~.custom-file-label {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.25);
}

.form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .form-inline .form-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
        display: inline-block;
    }
    .form-inline .input-group, .form-inline .custom-select {
        width: auto;
    }
    .form-inline .form-check {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }
    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }
    .form-inline .custom-control {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: none;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .btn {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.btn:hover, .btn:focus {
    text-decoration: none;
}

.btn:focus, .btn.focus {
    outline: 0;
}

.btn.disabled, .btn:disabled {
    opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show>.btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show>.btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
    color: #fff;
    background-color: #22b970;
    border-color: #22b970;
}

.btn-success:hover {
    color: #fff;
    background-color: #1c995c;
    border-color: #1a8e56;
}

.btn-success:focus, .btn-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #22b970;
    border-color: #22b970;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1a8e56;
    border-color: #18834f;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show>.btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.5);
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show>.btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show>.btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #e36e65;
    border-color: #e36e65;
}

.btn-danger:hover {
    color: #fff;
    background-color: #dd5045;
    border-color: #db453a;
}

.btn-danger:focus, .btn-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #e36e65;
    border-color: #e36e65;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #db453a;
    border-color: #d93b2f;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show>.btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.5);
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show>.btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show>.btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
    color: #22b970;
    background-color: transparent;
    background-image: none;
    border-color: #22b970;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #22b970;
    border-color: #22b970;
}

.btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #22b970;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #22b970;
    border-color: #22b970;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.5);
}

.btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107;
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
    color: #e36e65;
    background-color: transparent;
    background-image: none;
    border-color: #e36e65;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #e36e65;
    border-color: #e36e65;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #e36e65;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e36e65;
    border-color: #e36e65;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(227, 110, 101, 0.5);
}

.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #22b970;
    background-color: transparent;
}

.btn-link:hover {
    color: #167849;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn-lg, .btn-group-lg>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: none;
}

.btn-sm, .btn-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: none;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
    width: 100%;
}

.fade {
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
    .fade {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.dropup, .dropright, .dropdown, .dropleft {
    position: relative;
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: none;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}

.dropleft .dropdown-toggle::after {
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #22b970;
}

.dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.76563rem;
    color: #6c757d;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
}

.btn-group, .btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn, .btn-group-vertical>.btn {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}

.btn-group>.btn:hover, .btn-group-vertical>.btn:hover {
    z-index: 1;
}

.btn-group>.btn:focus, .btn-group>.btn:active, .btn-group>.btn.active, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn.active {
    z-index: 1;
}

.btn-group .btn+.btn, .btn-group .btn+.btn-group, .btn-group .btn-group+.btn, .btn-group .btn-group+.btn-group, .btn-group-vertical .btn+.btn, .btn-group-vertical .btn+.btn-group, .btn-group-vertical .btn-group+.btn, .btn-group-vertical .btn-group+.btn-group {
    margin-left: -1px;
}

.btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:first-child {
    margin-left: 0;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle), .btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child), .btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm+.dropdown-toggle-split, .btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split, .btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.btn-group-vertical .btn, .btn-group-vertical .btn-group {
    width: 100%;
}

.btn-group-vertical>.btn+.btn, .btn-group-vertical>.btn+.btn-group, .btn-group-vertical>.btn-group+.btn, .btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child), .btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn, .btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"], .btn-group-toggle>.btn input[type="checkbox"], .btn-group-toggle>.btn-group>.btn input[type="radio"], .btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control, .input-group>.custom-select, .input-group>.custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group>.form-control+.form-control, .input-group>.form-control+.custom-select, .input-group>.form-control+.custom-file, .input-group>.custom-select+.form-control, .input-group>.custom-select+.custom-select, .input-group>.custom-select+.custom-file, .input-group>.custom-file+.form-control, .input-group>.custom-file+.custom-select, .input-group>.custom-file+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control:focus, .input-group>.custom-select:focus, .input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group>.form-control:not(:last-child), .input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child), .input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label, .input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend, .input-group-append {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.input-group-prepend .btn, .input-group-append .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn+.btn, .input-group-prepend .btn+.input-group-text, .input-group-prepend .input-group-text+.input-group-text, .input-group-prepend .input-group-text+.btn, .input-group-append .btn+.btn, .input-group-append .btn+.input-group-text, .input-group-append .input-group-text+.input-group-text, .input-group-append .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #824e84;
    border-radius: none;
}

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] {
    margin-top: 0;
}

.input-group-lg>.form-control, .input-group-lg>.input-group-prepend>.input-group-text, .input-group-lg>.input-group-append>.input-group-text, .input-group-lg>.input-group-prepend>.btn, .input-group-lg>.input-group-append>.btn {
    height: calc(2.64063rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: none;
}

.input-group-sm>.form-control, .input-group-sm>.input-group-prepend>.input-group-text, .input-group-sm>.input-group-append>.input-group-text, .input-group-sm>.input-group-prepend>.btn, .input-group-sm>.input-group-append>.btn {
    height: calc(1.64844rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: none;
}

.input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text, .input-group>.input-group-append:not(:last-child)>.btn, .input-group>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #22b970;
}

.custom-control-input:focus~.custom-control-label::before {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
}

.custom-control-input:active~.custom-control-label::before {
    color: #fff;
    background-color: #a0eec8;
}

.custom-control-input:disabled~.custom-control-label {
    color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
}

.custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6;
}

.custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: none;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #22b970;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    background-color: #22b970;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #22b970;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #fff;
    vertical-align: middle;
    background: rgba(0, 0, 0, 0.2) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #824e84;
    border-radius: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-select:focus {
    border-color: #75e6af;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 230, 175, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(117, 230, 175, 0.5);
}

.custom-select:focus::-ms-value {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    opacity: 0;
}

.custom-select-sm {
    height: calc(1.64844rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
}

.custom-select-lg {
    height: calc(2.64063rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    /* margin-bottom: 0;  */
    margin: 5px auto;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.0625rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #75e6af;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
}

.custom-file-input:focus~.custom-file-label::after {
    border-color: #75e6af;
}

.custom-file-input:disabled~.custom-file-label {
    background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid #824e84;
    border-radius: none;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 25px;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.0625rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #fff;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #824e84;
    border-radius: 0 none none 0;
}

.custom-range {
    width: 100%;
    padding-left: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #22b970;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #a0eec8;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #22b970;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #a0eec8;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #22b970;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.custom-range::-ms-thumb:active {
    background-color: #a0eec8;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-control-label::before, .custom-file-label, .custom-select {
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before, .custom-file-label, .custom-select {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: none;
    border-top-right-radius: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: none;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #22b970;
}

.nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar>.container, .navbar>.container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.33594rem;
    padding-bottom: 0.33594rem;
    /* margin-right: 1rem; */
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: none;
}

.navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .navbar-expand-sm>.container, .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-sm>.container, .navbar-expand-sm>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar-expand>.container, .navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand>.container, .navbar-expand>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link, .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link, .navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: none;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: none;
    border-top-right-radius: none;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: none;
    border-bottom-left-radius: none;
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: calc(0px - 1px) calc(0px - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 calc(0px - 1px) calc(0px - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img {
    width: 100%;
    border-radius: calc(0px - 1px);
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0px - 1px);
    border-top-right-radius: calc(0px - 1px);
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0px - 1px);
    border-bottom-left-radius: calc(0px - 1px);
}

.card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .card-group>.card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group>.card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group>.card:first-child .card-img-top, .card-group>.card:first-child .card-header {
        border-top-right-radius: 0;
    }
    .card-group>.card:first-child .card-img-bottom, .card-group>.card:first-child .card-footer {
        border-bottom-right-radius: 0;
    }
    .card-group>.card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group>.card:last-child .card-img-top, .card-group>.card:last-child .card-header {
        border-top-left-radius: 0;
    }
    .card-group>.card:last-child .card-img-bottom, .card-group>.card:last-child .card-footer {
        border-bottom-left-radius: 0;
    }
    .card-group>.card:only-child {
        border-radius: none;
    }
    .card-group>.card:only-child .card-img-top, .card-group>.card:only-child .card-header {
        border-top-left-radius: none;
        border-top-right-radius: none;
    }
    .card-group>.card:only-child .card-img-bottom, .card-group>.card:only-child .card-footer {
        border-bottom-right-radius: none;
        border-bottom-left-radius: none;
    }
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
    }
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top, .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
        border-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
}

.accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: none;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: none;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #22b970;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link:hover {
    z-index: 2;
    color: #167849;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(34, 185, 112, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: none;
    border-bottom-left-radius: none;
}

.page-item:last-child .page-link {
    border-top-right-radius: none;
    border-bottom-right-radius: none;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #22b970;
    border-color: #22b970;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.09375rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: none;
    border-bottom-left-radius: none;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: none;
    border-bottom-right-radius: none;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: none;
    border-bottom-left-radius: none;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: none;
    border-bottom-right-radius: none;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #007bff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc;
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62;
}

.badge-success {
    color: #fff;
    background-color: #22b970;
}

.badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1a8e56;
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b;
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00;
}

.badge-danger {
    color: #fff;
    background-color: #e36e65;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #db453a;
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5;
}

.badge-dark {
    color: #fff;
    background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124;
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: none;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: none;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 3.8125rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #003f84;
    background-color: #cce4ff;
    border-color: #b7daff;
}

.alert-primary hr {
    border-top-color: #9ecdff;
}

.alert-primary .alert-link {
    color: #002751;
}

.alert-secondary {
    color: #383c41;
    background-color: #e1e3e5;
    border-color: #d5d8da;
}

.alert-secondary hr {
    border-top-color: #c7cbce;
}

.alert-secondary .alert-link {
    color: #202326;
}

.alert-success {
    color: #11603a;
    background-color: #d2f1e2;
    border-color: #c1ebd6;
}

.alert-success hr {
    border-top-color: #aee5c9;
}

.alert-success .alert-link {
    color: #093520;
}

.alert-info {
    color: #0b545f;
    background-color: #d0ecf0;
    border-color: #bee4eb;
}

.alert-info hr {
    border-top-color: #abdce5;
}

.alert-info .alert-link {
    color: #062c31;
}

.alert-warning {
    color: #846403;
    background-color: #fff2cd;
    border-color: #ffedb9;
}

.alert-warning hr {
    border-top-color: #ffe6a0;
}

.alert-warning .alert-link {
    color: #523e02;
}

.alert-danger {
    color: #763934;
    background-color: #f9e2e0;
    border-color: #f7d6d3;
}

.alert-danger hr {
    border-top-color: #f3c2bd;
}

.alert-danger .alert-link {
    color: #532824;
}

.alert-light {
    color: #808182;
    background-color: #fdfdfe;
    border-color: #fdfdfd;
}

.alert-light hr {
    border-top-color: #f0f0f0;
}

.alert-light .alert-link {
    color: #676868;
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d7d8;
    border-color: #c6c7c9;
}

.alert-dark hr {
    border-top-color: #b9babd;
}

.alert-dark .alert-link {
    color: #040405;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

.progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: #e9ecef;
    border-radius: none;
}

.progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: none;
    border-top-right-radius: none;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: none;
    border-bottom-left-radius: none;
}

.list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #22b970;
    border-color: #22b970;
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
}

.list-group-item-primary {
    color: #003f84;
    background-color: #b7daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #003f84;
    background-color: #9ecdff;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #003f84;
    border-color: #003f84;
}

.list-group-item-secondary {
    color: #383c41;
    background-color: #d5d8da;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383c41;
    background-color: #c7cbce;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383c41;
    border-color: #383c41;
}

.list-group-item-success {
    color: #11603a;
    background-color: #c1ebd6;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #11603a;
    background-color: #aee5c9;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #11603a;
    border-color: #11603a;
}

.list-group-item-info {
    color: #0b545f;
    background-color: #bee4eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0b545f;
    background-color: #abdce5;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0b545f;
    border-color: #0b545f;
}

.list-group-item-warning {
    color: #846403;
    background-color: #ffedb9;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #846403;
    background-color: #ffe6a0;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #846403;
    border-color: #846403;
}

.list-group-item-danger {
    color: #763934;
    background-color: #f7d6d3;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #763934;
    background-color: #f3c2bd;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #763934;
    border-color: #763934;
}

.list-group-item-light {
    color: #808182;
    background-color: #fdfdfd;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #808182;
    background-color: #f0f0f0;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #808182;
    border-color: #808182;
}

.list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c7c9;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9babd;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
}

.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    color: #000;
    text-decoration: none;
    opacity: .75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: "";
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: none;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.9;
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: none;
    border-top-right-radius: none;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.modal-footer> :not(:first-child) {
    margin-left: .25rem;
}

.modal-footer> :not(:last-child) {
    margin-right: .25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
    }
    .modal-dialog-centered::before {
        height: calc(100vh - (1.75rem * 2));
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76563rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: none;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76563rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: none;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 none;
}

.popover .arrow::before, .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before, .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: none 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before, .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before, .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: none 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before, .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0px - 1px);
    border-top-right-radius: calc(0px - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}

.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-item {
    position: relative;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
    display: block;
    -webkit-transition: -webkit-transform 0.6s ease;
    transition: -webkit-transform 0.6s ease;
    -o-transition: transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
    .carousel-item.active, .carousel-item-next, .carousel-item-prev {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.carousel-item-next, .carousel-item-prev {
    position: absolute;
    top: 0;
}

.carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.carousel-item-next, .active.carousel-item-right {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next, .active.carousel-item-right {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.carousel-item-prev, .active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev, .active.carousel-item-left {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-duration: .6s;
    -o-transition-duration: .6s;
    transition-duration: .6s;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    opacity: 0;
}

.carousel-fade .carousel-item-next, .carousel-fade .carousel-item-prev, .carousel-fade .carousel-item.active, .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-prev {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-fade .carousel-item-next, .carousel-fade .carousel-item-prev, .carousel-fade .carousel-item.active, .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-prev {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
}

.carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
}

.carousel-indicators .active {
    background-color: #fff;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
    background-color: #0062cc !important;
}

.bg-secondary {
    background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
    background-color: #545b62 !important;
}

.bg-success {
    background-color: #22b970 !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
    background-color: #1a8e56 !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
    background-color: #117a8b !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
    background-color: #d39e00 !important;
}

.bg-danger {
    background-color: #e36e65 !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
    background-color: #db453a !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
    background-color: #1d2124 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
    border-left: 1px solid #dee2e6 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #007bff !important;
}

.border-secondary {
    border-color: #6c757d !important;
}

.border-success {
    border-color: #22b970 !important;
}

.border-info {
    border-color: #17a2b8 !important;
}

.border-warning {
    border-color: #ffc107 !important;
}

.border-danger {
    border-color: #e36e65 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #343a40 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded {
    border-radius: none !important;
}

.rounded-top {
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
}

.rounded-right {
    border-top-right-radius: none !important;
    border-bottom-right-radius: none !important;
}

.rounded-bottom {
    border-bottom-right-radius: none !important;
    border-bottom-left-radius: none !important;
}

.rounded-left {
    border-top-left-radius: none !important;
    border-bottom-left-radius: none !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-md-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }
    .d-print-inline {
        display: inline !important;
    }
    .d-print-inline-block {
        display: inline-block !important;
    }
    .d-print-block {
        display: block !important;
    }
    .d-print-table {
        display: table !important;
    }
    .d-print-table-row {
        display: table-row !important;
    }
    .d-print-table-cell {
        display: table-cell !important;
    }
    .d-print-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-print-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
}

.flex-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
}

.justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}

.align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
}

.align-self-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .flex-sm-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .justify-content-sm-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .align-items-sm-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .align-items-sm-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
    }
    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
    }
    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .flex-md-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .justify-content-md-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .justify-content-md-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .align-items-md-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .align-items-md-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .align-items-md-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .align-items-md-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
    }
    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .align-self-md-center {
        -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
    }
    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .flex-lg-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .align-items-lg-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .align-items-lg-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
    }
    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
    }
    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .flex-xl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .justify-content-xl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .align-items-xl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .align-items-xl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
    }
    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
    }
    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }
    .float-sm-right {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }
    .float-md-right {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }
    .float-lg-right {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }
    .float-xl-right {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0, .my-0 {
    margin-top: 0 !important;
}

.mr-0, .mx-0 {
    margin-right: 0 !important;
}

.mb-0, .my-0 {
    margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1, .my-1 {
    margin-top: 0.25rem !important;
}

.mr-1, .mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1, .my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1, .mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2, .my-2 {
    margin-top: 0.5rem !important;
}

.mr-2, .mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2, .my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2, .mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3, .my-3 {
    margin-top: 1rem !important;
}

.mr-3, .mx-3 {
    margin-right: 1rem !important;
}

.mb-3, .my-3 {
    margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4, .my-4 {
    margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5, .my-5 {
    margin-top: 3rem !important;
}

.mr-5, .mx-5 {
    margin-right: 3rem !important;
}

.mb-5, .my-5 {
    margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0, .py-0 {
    padding-top: 0 !important;
}

.pr-0, .px-0 {
    padding-right: 0 !important;
}

.pb-0, .py-0 {
    padding-bottom: 0 !important;
}

.pl-0, .px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1, .py-1 {
    padding-top: 0.25rem !important;
}

.pr-1, .px-1 {
    padding-right: 0.25rem !important;
}

.pb-1, .py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1, .px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2, .py-2 {
    padding-top: 0.5rem !important;
}

.pr-2, .px-2 {
    padding-right: 0.5rem !important;
}

.pb-2, .py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2, .px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3, .py-3 {
    padding-top: 1rem !important;
}

.pr-3, .px-3 {
    padding-right: 1rem !important;
}

.pb-3, .py-3 {
    padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4, .py-4 {
    padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
    padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5, .py-5 {
    padding-top: 3rem !important;
}

.pr-5, .px-5 {
    padding-right: 3rem !important;
}

.pb-5, .py-5 {
    padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
    padding-left: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto, .my-auto {
    margin-top: auto !important;
}

.mr-auto, .mx-auto {
    margin-right: auto !important;
}

.mb-auto, .my-auto {
    margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }
    .mt-sm-0, .my-sm-0 {
        margin-top: 0 !important;
    }
    .mr-sm-0, .mx-sm-0 {
        margin-right: 0 !important;
    }
    .mb-sm-0, .my-sm-0 {
        margin-bottom: 0 !important;
    }
    .ml-sm-0, .mx-sm-0 {
        margin-left: 0 !important;
    }
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    .mt-sm-1, .my-sm-1 {
        margin-top: 0.25rem !important;
    }
    .mr-sm-1, .mx-sm-1 {
        margin-right: 0.25rem !important;
    }
    .mb-sm-1, .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-sm-1, .mx-sm-1 {
        margin-left: 0.25rem !important;
    }
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    .mt-sm-2, .my-sm-2 {
        margin-top: 0.5rem !important;
    }
    .mr-sm-2, .mx-sm-2 {
        margin-right: 0.5rem !important;
    }
    .mb-sm-2, .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-sm-2, .mx-sm-2 {
        margin-left: 0.5rem !important;
    }
    .m-sm-3 {
        margin: 1rem !important;
    }
    .mt-sm-3, .my-sm-3 {
        margin-top: 1rem !important;
    }
    .mr-sm-3, .mx-sm-3 {
        margin-right: 1rem !important;
    }
    .mb-sm-3, .my-sm-3 {
        margin-bottom: 1rem !important;
    }
    .ml-sm-3, .mx-sm-3 {
        margin-left: 1rem !important;
    }
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    .mt-sm-4, .my-sm-4 {
        margin-top: 1.5rem !important;
    }
    .mr-sm-4, .mx-sm-4 {
        margin-right: 1.5rem !important;
    }
    .mb-sm-4, .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-4, .mx-sm-4 {
        margin-left: 1.5rem !important;
    }
    .m-sm-5 {
        margin: 3rem !important;
    }
    .mt-sm-5, .my-sm-5 {
        margin-top: 3rem !important;
    }
    .mr-sm-5, .mx-sm-5 {
        margin-right: 3rem !important;
    }
    .mb-sm-5, .my-sm-5 {
        margin-bottom: 3rem !important;
    }
    .ml-sm-5, .mx-sm-5 {
        margin-left: 3rem !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .pt-sm-0, .py-sm-0 {
        padding-top: 0 !important;
    }
    .pr-sm-0, .px-sm-0 {
        padding-right: 0 !important;
    }
    .pb-sm-0, .py-sm-0 {
        padding-bottom: 0 !important;
    }
    .pl-sm-0, .px-sm-0 {
        padding-left: 0 !important;
    }
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    .pt-sm-1, .py-sm-1 {
        padding-top: 0.25rem !important;
    }
    .pr-sm-1, .px-sm-1 {
        padding-right: 0.25rem !important;
    }
    .pb-sm-1, .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-sm-1, .px-sm-1 {
        padding-left: 0.25rem !important;
    }
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    .pt-sm-2, .py-sm-2 {
        padding-top: 0.5rem !important;
    }
    .pr-sm-2, .px-sm-2 {
        padding-right: 0.5rem !important;
    }
    .pb-sm-2, .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-sm-2, .px-sm-2 {
        padding-left: 0.5rem !important;
    }
    .p-sm-3 {
        padding: 1rem !important;
    }
    .pt-sm-3, .py-sm-3 {
        padding-top: 1rem !important;
    }
    .pr-sm-3, .px-sm-3 {
        padding-right: 1rem !important;
    }
    .pb-sm-3, .py-sm-3 {
        padding-bottom: 1rem !important;
    }
    .pl-sm-3, .px-sm-3 {
        padding-left: 1rem !important;
    }
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    .pt-sm-4, .py-sm-4 {
        padding-top: 1.5rem !important;
    }
    .pr-sm-4, .px-sm-4 {
        padding-right: 1.5rem !important;
    }
    .pb-sm-4, .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-4, .px-sm-4 {
        padding-left: 1.5rem !important;
    }
    .p-sm-5 {
        padding: 3rem !important;
    }
    .pt-sm-5, .py-sm-5 {
        padding-top: 3rem !important;
    }
    .pr-sm-5, .px-sm-5 {
        padding-right: 3rem !important;
    }
    .pb-sm-5, .py-sm-5 {
        padding-bottom: 3rem !important;
    }
    .pl-sm-5, .px-sm-5 {
        padding-left: 3rem !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mt-sm-auto, .my-sm-auto {
        margin-top: auto !important;
    }
    .mr-sm-auto, .mx-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-auto, .my-sm-auto {
        margin-bottom: auto !important;
    }
    .ml-sm-auto, .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }
    .mt-md-0, .my-md-0 {
        margin-top: 0 !important;
    }
    .mr-md-0, .mx-md-0 {
        margin-right: 0 !important;
    }
    .mb-md-0, .my-md-0 {
        margin-bottom: 0 !important;
    }
    .ml-md-0, .mx-md-0 {
        margin-left: 0 !important;
    }
    .m-md-1 {
        margin: 0.25rem !important;
    }
    .mt-md-1, .my-md-1 {
        margin-top: 0.25rem !important;
    }
    .mr-md-1, .mx-md-1 {
        margin-right: 0.25rem !important;
    }
    .mb-md-1, .my-md-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-md-1, .mx-md-1 {
        margin-left: 0.25rem !important;
    }
    .m-md-2 {
        margin: 0.5rem !important;
    }
    .mt-md-2, .my-md-2 {
        margin-top: 0.5rem !important;
    }
    .mr-md-2, .mx-md-2 {
        margin-right: 0.5rem !important;
    }
    .mb-md-2, .my-md-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-md-2, .mx-md-2 {
        margin-left: 0.5rem !important;
    }
    .m-md-3 {
        margin: 1rem !important;
    }
    .mt-md-3, .my-md-3 {
        margin-top: 1rem !important;
    }
    .mr-md-3, .mx-md-3 {
        margin-right: 1rem !important;
    }
    .mb-md-3, .my-md-3 {
        margin-bottom: 1rem !important;
    }
    .ml-md-3, .mx-md-3 {
        margin-left: 1rem !important;
    }
    .m-md-4 {
        margin: 1.5rem !important;
    }
    .mt-md-4, .my-md-4 {
        margin-top: 1.5rem !important;
    }
    .mr-md-4, .mx-md-4 {
        margin-right: 1.5rem !important;
    }
    .mb-md-4, .my-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-4, .mx-md-4 {
        margin-left: 1.5rem !important;
    }
    .m-md-5 {
        margin: 3rem !important;
    }
    .mt-md-5, .my-md-5 {
        margin-top: 3rem !important;
    }
    .mr-md-5, .mx-md-5 {
        margin-right: 3rem !important;
    }
    .mb-md-5, .my-md-5 {
        margin-bottom: 3rem !important;
    }
    .ml-md-5, .mx-md-5 {
        margin-left: 3rem !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .pt-md-0, .py-md-0 {
        padding-top: 0 !important;
    }
    .pr-md-0, .px-md-0 {
        padding-right: 0 !important;
    }
    .pb-md-0, .py-md-0 {
        padding-bottom: 0 !important;
    }
    .pl-md-0, .px-md-0 {
        padding-left: 0 !important;
    }
    .p-md-1 {
        padding: 0.25rem !important;
    }
    .pt-md-1, .py-md-1 {
        padding-top: 0.25rem !important;
    }
    .pr-md-1, .px-md-1 {
        padding-right: 0.25rem !important;
    }
    .pb-md-1, .py-md-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-md-1, .px-md-1 {
        padding-left: 0.25rem !important;
    }
    .p-md-2 {
        padding: 0.5rem !important;
    }
    .pt-md-2, .py-md-2 {
        padding-top: 0.5rem !important;
    }
    .pr-md-2, .px-md-2 {
        padding-right: 0.5rem !important;
    }
    .pb-md-2, .py-md-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-md-2, .px-md-2 {
        padding-left: 0.5rem !important;
    }
    .p-md-3 {
        padding: 1rem !important;
    }
    .pt-md-3, .py-md-3 {
        padding-top: 1rem !important;
    }
    .pr-md-3, .px-md-3 {
        padding-right: 1rem !important;
    }
    .pb-md-3, .py-md-3 {
        padding-bottom: 1rem !important;
    }
    .pl-md-3, .px-md-3 {
        padding-left: 1rem !important;
    }
    .p-md-4 {
        padding: 1.5rem !important;
    }
    .pt-md-4, .py-md-4 {
        padding-top: 1.5rem !important;
    }
    .pr-md-4, .px-md-4 {
        padding-right: 1.5rem !important;
    }
    .pb-md-4, .py-md-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-4, .px-md-4 {
        padding-left: 1.5rem !important;
    }
    .p-md-5 {
        padding: 3rem !important;
    }
    .pt-md-5, .py-md-5 {
        padding-top: 3rem !important;
    }
    .pr-md-5, .px-md-5 {
        padding-right: 3rem !important;
    }
    .pb-md-5, .py-md-5 {
        padding-bottom: 3rem !important;
    }
    .pl-md-5, .px-md-5 {
        padding-left: 3rem !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mt-md-auto, .my-md-auto {
        margin-top: auto !important;
    }
    .mr-md-auto, .mx-md-auto {
        margin-right: auto !important;
    }
    .mb-md-auto, .my-md-auto {
        margin-bottom: auto !important;
    }
    .ml-md-auto, .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }
    .mt-lg-0, .my-lg-0 {
        margin-top: 0 !important;
    }
    .mr-lg-0, .mx-lg-0 {
        margin-right: 0 !important;
    }
    .mb-lg-0, .my-lg-0 {
        margin-bottom: 0 !important;
    }
    .ml-lg-0, .mx-lg-0 {
        margin-left: 0 !important;
    }
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    .mt-lg-1, .my-lg-1 {
        margin-top: 0.25rem !important;
    }
    .mr-lg-1, .mx-lg-1 {
        margin-right: 0.25rem !important;
    }
    .mb-lg-1, .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-lg-1, .mx-lg-1 {
        margin-left: 0.25rem !important;
    }
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    .mt-lg-2, .my-lg-2 {
        margin-top: 0.5rem !important;
    }
    .mr-lg-2, .mx-lg-2 {
        margin-right: 0.5rem !important;
    }
    .mb-lg-2, .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-lg-2, .mx-lg-2 {
        margin-left: 0.5rem !important;
    }
    .m-lg-3 {
        margin: 1rem !important;
    }
    .mt-lg-3, .my-lg-3 {
        margin-top: 1rem !important;
    }
    .mr-lg-3, .mx-lg-3 {
        margin-right: 1rem !important;
    }
    .mb-lg-3, .my-lg-3 {
        margin-bottom: 1rem !important;
    }
    .ml-lg-3, .mx-lg-3 {
        margin-left: 1rem !important;
    }
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    .mt-lg-4, .my-lg-4 {
        margin-top: 1.5rem !important;
    }
    .mr-lg-4, .mx-lg-4 {
        margin-right: 1.5rem !important;
    }
    .mb-lg-4, .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-4, .mx-lg-4 {
        margin-left: 1.5rem !important;
    }
    .m-lg-5 {
        margin: 3rem !important;
    }
    .mt-lg-5, .my-lg-5 {
        margin-top: 3rem !important;
    }
    .mr-lg-5, .mx-lg-5 {
        margin-right: 3rem !important;
    }
    .mb-lg-5, .my-lg-5 {
        margin-bottom: 3rem !important;
    }
    .ml-lg-5, .mx-lg-5 {
        margin-left: 3rem !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .pt-lg-0, .py-lg-0 {
        padding-top: 0 !important;
    }
    .pr-lg-0, .px-lg-0 {
        padding-right: 0 !important;
    }
    .pb-lg-0, .py-lg-0 {
        padding-bottom: 0 !important;
    }
    .pl-lg-0, .px-lg-0 {
        padding-left: 0 !important;
    }
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    .pt-lg-1, .py-lg-1 {
        padding-top: 0.25rem !important;
    }
    .pr-lg-1, .px-lg-1 {
        padding-right: 0.25rem !important;
    }
    .pb-lg-1, .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-lg-1, .px-lg-1 {
        padding-left: 0.25rem !important;
    }
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    .pt-lg-2, .py-lg-2 {
        padding-top: 0.5rem !important;
    }
    .pr-lg-2, .px-lg-2 {
        padding-right: 0.5rem !important;
    }
    .pb-lg-2, .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-lg-2, .px-lg-2 {
        padding-left: 0.5rem !important;
    }
    .p-lg-3 {
        padding: 1rem !important;
    }
    .pt-lg-3, .py-lg-3 {
        padding-top: 1rem !important;
    }
    .pr-lg-3, .px-lg-3 {
        padding-right: 1rem !important;
    }
    .pb-lg-3, .py-lg-3 {
        padding-bottom: 1rem !important;
    }
    .pl-lg-3, .px-lg-3 {
        padding-left: 1rem !important;
    }
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    .pt-lg-4, .py-lg-4 {
        padding-top: 1.5rem !important;
    }
    .pr-lg-4, .px-lg-4 {
        padding-right: 1.5rem !important;
    }
    .pb-lg-4, .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-4, .px-lg-4 {
        padding-left: 1.5rem !important;
    }
    .p-lg-5 {
        padding: 3rem !important;
    }
    .pt-lg-5, .py-lg-5 {
        padding-top: 3rem !important;
    }
    .pr-lg-5, .px-lg-5 {
        padding-right: 3rem !important;
    }
    .pb-lg-5, .py-lg-5 {
        padding-bottom: 3rem !important;
    }
    .pl-lg-5, .px-lg-5 {
        padding-left: 3rem !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mt-lg-auto, .my-lg-auto {
        margin-top: auto !important;
    }
    .mr-lg-auto, .mx-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-auto, .my-lg-auto {
        margin-bottom: auto !important;
    }
    .ml-lg-auto, .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }
    .mt-xl-0, .my-xl-0 {
        margin-top: 0 !important;
    }
    .mr-xl-0, .mx-xl-0 {
        margin-right: 0 !important;
    }
    .mb-xl-0, .my-xl-0 {
        margin-bottom: 0 !important;
    }
    .ml-xl-0, .mx-xl-0 {
        margin-left: 0 !important;
    }
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    .mt-xl-1, .my-xl-1 {
        margin-top: 0.25rem !important;
    }
    .mr-xl-1, .mx-xl-1 {
        margin-right: 0.25rem !important;
    }
    .mb-xl-1, .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-xl-1, .mx-xl-1 {
        margin-left: 0.25rem !important;
    }
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    .mt-xl-2, .my-xl-2 {
        margin-top: 0.5rem !important;
    }
    .mr-xl-2, .mx-xl-2 {
        margin-right: 0.5rem !important;
    }
    .mb-xl-2, .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-xl-2, .mx-xl-2 {
        margin-left: 0.5rem !important;
    }
    .m-xl-3 {
        margin: 1rem !important;
    }
    .mt-xl-3, .my-xl-3 {
        margin-top: 1rem !important;
    }
    .mr-xl-3, .mx-xl-3 {
        margin-right: 1rem !important;
    }
    .mb-xl-3, .my-xl-3 {
        margin-bottom: 1rem !important;
    }
    .ml-xl-3, .mx-xl-3 {
        margin-left: 1rem !important;
    }
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    .mt-xl-4, .my-xl-4 {
        margin-top: 1.5rem !important;
    }
    .mr-xl-4, .mx-xl-4 {
        margin-right: 1.5rem !important;
    }
    .mb-xl-4, .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-4, .mx-xl-4 {
        margin-left: 1.5rem !important;
    }
    .m-xl-5 {
        margin: 3rem !important;
    }
    .mt-xl-5, .my-xl-5 {
        margin-top: 3rem !important;
    }
    .mr-xl-5, .mx-xl-5 {
        margin-right: 3rem !important;
    }
    .mb-xl-5, .my-xl-5 {
        margin-bottom: 3rem !important;
    }
    .ml-xl-5, .mx-xl-5 {
        margin-left: 3rem !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .pt-xl-0, .py-xl-0 {
        padding-top: 0 !important;
    }
    .pr-xl-0, .px-xl-0 {
        padding-right: 0 !important;
    }
    .pb-xl-0, .py-xl-0 {
        padding-bottom: 0 !important;
    }
    .pl-xl-0, .px-xl-0 {
        padding-left: 0 !important;
    }
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    .pt-xl-1, .py-xl-1 {
        padding-top: 0.25rem !important;
    }
    .pr-xl-1, .px-xl-1 {
        padding-right: 0.25rem !important;
    }
    .pb-xl-1, .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-xl-1, .px-xl-1 {
        padding-left: 0.25rem !important;
    }
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    .pt-xl-2, .py-xl-2 {
        padding-top: 0.5rem !important;
    }
    .pr-xl-2, .px-xl-2 {
        padding-right: 0.5rem !important;
    }
    .pb-xl-2, .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-xl-2, .px-xl-2 {
        padding-left: 0.5rem !important;
    }
    .p-xl-3 {
        padding: 1rem !important;
    }
    .pt-xl-3, .py-xl-3 {
        padding-top: 1rem !important;
    }
    .pr-xl-3, .px-xl-3 {
        padding-right: 1rem !important;
    }
    .pb-xl-3, .py-xl-3 {
        padding-bottom: 1rem !important;
    }
    .pl-xl-3, .px-xl-3 {
        padding-left: 1rem !important;
    }
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    .pt-xl-4, .py-xl-4 {
        padding-top: 1.5rem !important;
    }
    .pr-xl-4, .px-xl-4 {
        padding-right: 1.5rem !important;
    }
    .pb-xl-4, .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-4, .px-xl-4 {
        padding-left: 1.5rem !important;
    }
    .p-xl-5 {
        padding: 3rem !important;
    }
    .pt-xl-5, .py-xl-5 {
        padding-top: 3rem !important;
    }
    .pr-xl-5, .px-xl-5 {
        padding-right: 3rem !important;
    }
    .pb-xl-5, .py-xl-5 {
        padding-bottom: 3rem !important;
    }
    .pl-xl-5, .px-xl-5 {
        padding-left: 3rem !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mt-xl-auto, .my-xl-auto {
        margin-top: auto !important;
    }
    .mr-xl-auto, .mx-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-auto, .my-xl-auto {
        margin-bottom: auto !important;
    }
    .ml-xl-auto, .mx-xl-auto {
        margin-left: auto !important;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
    text-align: justify !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }
    .text-sm-right {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }
    .text-md-right {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }
    .text-xl-right {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
    color: #0062cc !important;
}

.text-secondary {
    color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
    color: #545b62 !important;
}

.text-success {
    color: #22b970 !important;
}

a.text-success:hover, a.text-success:focus {
    color: #1a8e56 !important;
}

.text-info {
    color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
    color: #117a8b !important;
}

.text-warning {
    color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
    color: #d39e00 !important;
}

.text-danger {
    color: #e36e65 !important;
}

a.text-danger:hover, a.text-danger:focus {
    color: #db453a !important;
}

.text-light {
    color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
    color: #dae0e5 !important;
}

.text-dark {
    color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
    color: #1d2124 !important;
}

.text-body {
    color: #212529 !important;
}

.text-muted {
    color: #6c757d !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

@media print {
    *, *::before, *::after {
        text-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    abbr[title]::after {
        content: " (" attr(title) ")";
    }
    pre {
        white-space: pre-wrap !important;
    }
    pre, blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr, img {
        page-break-inside: avoid;
    }
    p, h2, h3 {
        orphans: 3;
        widows: 3;
    }
    h2, h3 {
        page-break-after: avoid;
    }
    @page {
        size: a3;
    }
    body {
        min-width: 992px !important;
    }
    .container {
        min-width: 992px !important;
    }
    .navbar {
        display: none;
    }
    .badge {
        border: 1px solid #000;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td, .table th {
        background-color: #fff !important;
    }
    .table-bordered th, .table-bordered td {
        border: 1px solid #dee2e6 !important;
    }
    .table-dark {
        color: inherit;
    }
    .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody+tbody {
        border-color: #dee2e6;
    }
    .table .thead-dark th {
        color: inherit;
        border-color: #dee2e6;
    }
}

/*Custome css starts form here*/

@font-face {
    font-family: 'karlaRegular';
    src: url("../fonts/karlaRegular.eot?") format("eot"), url("../fonts/karlaRegular.woff") format("woff"), url("../fonts/karlaRegular.ttf") format("truetype"), url("../fonts/karlaRegular.svg#karlaRegular") format("svg");
    font-weight: 400;
}

@font-face {
    font-family: 'karlaBold';
    src: url("../fonts/karlaBold.eot?") format("eot"), url("../fonts/karlaBold.woff") format("woff"), url("../fonts/karlaBold.ttf") format("truetype"), url("../fonts/karlaBold.svg#karlaBold") format("svg");
    font-weight: 700;
}

@font-face {
    font-family: 'karlaItalic';
    src: url("../fonts/karlaItalic.eot?") format("eot"), url("../fonts/karlaItalic.woff") format("woff"), url("../fonts/karlaItalic.ttf") format("truetype"), url("../fonts/karlaItalic.svg#karlaItalic") format("svg");
    font-weight: 400;
}

/*@import "root";*/

body {
    font-family: 'karlaRegular';
    color: #ffffff;
    line-height: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    background-color: #7a359e;
}

.usm-background {
    background: url("../images/background-bg.png") no-repeat center center fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.usm-outer-container {
    padding: 0% 3%;
    max-width: 1366px;
    width: 100%;
    margin: 0px auto;
}

.trans-bg {
    background: rgba(3, 3, 3, 0.3);
    width: 100%;
    height: 100%;
    padding: 20px;
}

.usm-form {
    position: relative;
    width: 100%;
}

.usm-form label {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
}

.form-control {
    border-color: #ffffff;
    height: 38px;
}

.form-control:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    border-color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-border .form-group .form-control {
    border-color: rgba(255, 255, 255, 0.2);
    /*	&:valid{
  		background: #ffffff;
  		color: #000000;
  	}*/
}

.form-border .form-group .form-control:focus {
    border-color: #ffffff;
}

.usm-modal .usm-modal-header, .usm-modal .usm-modal-header-tc {
    background: url("../images/login-header.png") no-repeat center center;
    background-size: cover;
    text-align: center;
    width: 100%;
    height: 100%;
    min-height: 197px;
    position: relative;
    border-bottom: none;
}

.usm-modal .usm-modal-header img, .usm-modal .usm-modal-header-tc img {
    width: auto;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.usm-modal .usm-modal-header-tc {
    background: url("../images/t-and-c-header.png") no-repeat center center;
}

.usm-modal .usm-modal-body {
    width: 100%;
    min-height: 333px;
    height: 100%;
    background: rgba(3, 3, 3, 0.2);
    padding: 16px 40px;
    padding-bottom: 40px;
}

.usm-modal .usm-modal-body h2 {
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    font-family: 'karlaBold';
    margin-top: 13px;
    margin-bottom: 32px;
}

.usm-modal .usm-modal-body p {
    font-size: 13px;
    color: #ffffff;
    line-height: 24px;
    margin-bottom: 20px;
}

.usm-modal .usm-modal-body p:last-child {
    margin-bottom: 0px;
}

.usm-modal .usm-modal-body .static-alert {
    font-size: 13px;
    color: #ffffff;
    line-height: normal;
}

.usm-modal .usm-modal-body .width-container {
    max-width: 280px;
    /* max-width: 312px; */
    width: 100%;
    margin: 0px auto;
    word-break: normal;
}

.usm-modal .usm-modal-body .btn {
    text-transform: uppercase;
    font-size: 13px;
    font-family: 'karlaBold';
    /* min-height: 38px; */
    margin: 0px 9px;
    margin-top: 32px;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-width: 201px;
}

.usm-modal .usm-modal-body .form-group {
    margin-bottom: 10px;
    position: relative;
}

.usm-modal .usm-modal-body .form-group .display-error {
    position: absolute;
    background: #e36e65;
    padding: 5px 15px;
    font-size: 12px;
    color: #ffffff;
    z-index: 1;
    border-radius: 3px;
    top: 42px;
}

.usm-modal .usm-modal-body .form-group .display-error:after {
    bottom: 100%;
    left: 23%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: #e36e65;
    border-width: 6px;
    margin-left: -30px;
}

.usm-modal .usm-modal-body .form-error label {
    color: #e36e65;
}

.usm-modal .usm-modal-body .form-error .form-control {
    border-color: #e36e65;
}

.form-modal .form-group {
    margin-bottom: 15px;
    position: relative;
}

.form-modal .form-group .display-error {
    position: absolute;
    background: #e36e65;
    padding: 5px 15px;
    font-size: 12px;
    color: #ffffff;
    z-index: 1;
    border-radius: 3px;
    top: 74px;
}

.form-modal .form-group .display-error:after {
    bottom: 100%;
    /* left: 23%; */
    left: 50px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: #e36e65;
    border-width: 6px;
    margin-left: -30px;
}

.form-modal .form-error label {
    color: #e36e65;
}

.form-modal .form-error .form-control {
    border-color: #e36e65;
}

.form-modal .form-error .custom-file label {
    border-color: #e36e65;
    color: rgba(255, 255, 255, 0.6);
    font-size: 13px;
}

.form-modal .form-error .custom-file label:focus {
    color: #ffffff;
}

.form-modal .form-error .input-group-text {
    border-color: #e36e65;
}

.form-modal .input-group-text {
    padding: 0px 6px;
    background: rgba(0, 0, 0, 0.2);
    outline: 0;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-right: none;
    color: rgba(255, 255, 255, 0.6);
}

.form-modal .modal-dialog .modal-content {
    background: url("../images/form-bg.png") no-repeat;
    background-size: cover;
    min-height: 488px;
}

.form-modal .form-modal-header {
    background: rgba(8, 1, 1, 0.45);
    text-align: center;
    width: 100%;
    /*height: 100%;*/
    min-height: 82px;
    position: relative;
    border-bottom: none;
    padding: 20px 35px;
}

.form-modal .form-modal-header .form-modal-title {
    color: #ffffff;
    font-size: 24px;
    font-family: 'karlaBold';
    margin-bottom: 0px;
    /* margin-left: -9%; */
}

.form-modal .form-modal-header .modal-close {
    float: right;
    position: relative;
    padding:0px;
}

.form-modal .form-modal-body {
    padding: 20px 35px;
}

.form-modal .form-modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 30px 35px 40px 35px;
}

/*add photo section modal*/

.add-photo-section {
    padding: 20px 0px 0px 0px;
}

.add-photo-section .form-group {
    /*padding-top:5%;*/
    width: 62%;
}

.add-photo-section .upload-image {
    width: 100%;
    max-width: 122px;
    /*	height: 123px;*/
}

.add-photo-section .upload-image img {
    max-width: 122px;
    max-height: 123px;
    float: right;
}

/*browse file button*/

.custom-file-label::after {
    background-color: none;
    border-left: none;
    content: "";
    background: url("../images/browse-image.png") no-repeat 0px 9px;
    width: 28px;
    cursor: pointer;
}

.custom-file-label.add-file-input:after {
    background: url("../images/add-file-input.png") no-repeat 0px 9px;
    width: 16px;
    cursor: pointer;
}

.custom-file-label.close-file-input:after {
 background: none;
}
.custom-file-input:lang(en)~.custom-file-label::after {
    content: "";
}

.custom-file-input:focus~.custom-file-label {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    border-color: inherit;
}

.form-transDivider {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.transDivider {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
}

.maxWidth420 {
    max-width: 420px;
}

.maxWidth800 {
    max-width: 800px;
}

.maxWidth800 .usm-modal-body {
    padding-bottom: 40px;
}

.maxWidth600 {
    max-width: 600px;
}

.usm-check input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.usm-check label {
    position: relative;
    cursor: pointer;
    font-size: 13px;
    margin-bottom: 0px;
    font-family: 'karlaRegular';
    display: flex;
    align-items: center;

}

.usm-check label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    /*#25bf75*/
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 13px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    margin-right: 12px;
}

#mainContainer.toggled .usm-check label:before{
    float:left;
}

.usm-check input:checked+label {
    color: #ffffff;
}

.usm-check input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 11px;
    width: 7px;
    height: 16px;
    border: solid #25bf75;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.usm-check input:checked+label:before {
    border: 1px solid #25bf75;
}

.usm-radio [type="radio"]:checked, .usm-radio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.usm-radio [type="radio"]:checked+label, .usm-radio [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 45px;
    padding-top: 5px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
}
.usm-radio input:checked+label {
  color: #ffffff;
}
.usm-radio [type="radio"]:checked+label:before, .usm-radio [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border: 1px solid #22b970;
    border-radius: 100%;
}

.usm-radio [type="radio"]:checked+label:after, .usm-radio [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 18px;
    height: 18px;
    background: #22b970;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.usm-radio [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.usm-radio [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.usm-form-subtext {
    text-align: center;
}

.usm-form-subtext a {
    font-size: 14px;
    color: #ffffff;
}

#mainContainer {
    padding: 0px 0px;
    padding-bottom: 130px;
    position: relative;
    }

    

/*header section start here*/

.welcome-header .nav {
    min-height: 55px;
}

.welcome-header .nav-link {
    padding-right: 8px;
    padding-left: 8px;
    height: 55px;
}

.user-dropdown-icon {
    padding-right: 0px;
    padding-left: 0px;
}

.user-dropdown-icon img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #7d5c91;
    padding: 2px;
    text-align: center;
}

.header {
    min-height: 80px;
    background: rgba(3, 3, 3, 0.2);
}

.header .navbar {
    min-height: 80px;
}

.header .navbar .navbar-brand {
    padding: 0px;
    margin-left: -15px;
    margin-top: -8px;
    margin-bottom: -8px;
}

.header .navbar ul.usm-navbar .nav-item {
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'karlaRegular';
    font-size: 14px;
}

.header .navbar ul.usm-navbar .nav-item .nav-link {
    border-bottom: 3px solid transparent;
    padding: 0rem !important;
}

.header .navbar ul.usm-navbar .nav-item .nav-link:hover {
    border-bottom: 3px solid #22b970;
}

.header .navbar ul.usm-navbar .nav-item .nav-link:active {
    border-bottom: 3px solid #22b970;
    font-family: 'karlaBold';
}

.header .navbar ul.usm-navbar .nav-item .nav-link.active {
    border-bottom: 3px solid #22b970;
    font-family: 'karlaBold';
}

.header .navbar-light .navbar-toggler-icon {
    background-image: none;
}

.header .navbar-light .navbar-toggler-icon i {
    color: #ffffff;
}

/*usm search*/

.usm-search {
    border: none;
    border-bottom: 1px solid #8b6f9e;
    background: none;
    /*min-width: 340px;*/
    padding-left: 0px;
    color: #ffffff;
    font-size: 14px;
    font-style: italic;
    padding: 3px 15px 3px 0px;
    height: 25px;
    background: url(../images/search-btn.png) right center no-repeat;
    background-size: 15px;
}

.usm-search:focus, .usm-search:active, .usm-search.active {
    /*background: none;*/
    background: url(../images/search-btn-active.png) right center no-repeat;
    background-size: 15px;
    border-bottom: 1px solid #22b970;
}

.search-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 19px;
    height: 19px;
    /*background: url(../images/search-btn.png)no-repeat;		*/
}

.global-search-block .dropdown-toggle:after {
    display: none;
}

.global-search-block .global-search-result {
    background: #642c79;
    width: 100%;
    width: 723px;
    padding: 38px;
}

/*heading section*/

.heading-section {
    font-family: 'karlaRegular';
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
    height: 110px;
}

.heading-section a {
    color: #ffffff;
}

.heading-section a:hover {
    text-decoration: none;
    color: #22b970;
}

.heading-section.auto-height {
    min-height: auto;
    margin-bottom: 25px;
}

.heading-section.height84 {
    min-height: 84px;
    margin-bottom: 30px;
}


.heading-section .badge {
    font-size: 13px;
    min-width: 42px;
    padding: 0.15em 0.4em;
    margin-left: 6px;
}

.heading-section h2 {
    font-size: 30px;
    font-family: 'karlaBold';
    margin: 0px;
    padding: 0px;
    text-transform: none;
}

.heading-section h6 {
    font-size: 14px;
    font-family: 'karlaBold';
    margin: 0px;
    padding: 0px;
}

/*icons*/

.user-menu-icon {
    color: #22b970;
    font-size: 30px !important;
    vertical-align: bottom;
    margin-bottom: -2px;
}

/*filtter section*/

.filtter-section {
    min-height: 93px;
    background: rgba(3, 3, 3, 0.2);
    padding: 18px 20px;
}

.filtter-section .apply-filtter {
    /* border-left: 1px solid rgba(0, 0, 0, 0.2); */
    padding-left: 20px;
    text-align: center;
    padding-bottom: 5px;
}
.filtter-section .apply-filtter i{
    margin-top:-7px;
}

.filtter-section .filter-form .form-control {
    height: 30px;
    border-color: rgba(255, 255, 255, 0.1);
}

.purpleBg-dropdown {
    background-color: #7a359e;
    min-width: auto;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.5);
}

.purpleBg-dropdown .dropdown-item {
    color: #ffffff;
    font-size: 14px;
    font-family: 'karlaBold';
    padding: 4px 15px 4px 35px;
}

.purpleBg-dropdown .dropdown-item:hover, .purpleBg-dropdown .dropdown-item:active, .purpleBg-dropdown .dropdown-item.active {
    background-color: #7a359e !important;
    color: #22b970;
    background: url("../images/active-check.png") no-repeat 20px 10px;
}

.purpleBg-dropdown.ai-dropdown {
    width: 155px;
}

.purpleBg-dropdown.note-popup-drodown {
    min-width: 578px;
    padding: 18px;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(3%, -50%) !important;
    -ms-transform: translate(3%, -50%) !important;
    transform: translate(3%, -50%) !important;
}

.purpleBg-dropdown.purpleBg-top:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: #7a359e;
    border-width: 6px;
}

.purpleBg-dropdown.purpleBg-left:after, .purpleBg-dropdown.purpleBg-left:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.purpleBg-dropdown.purpleBg-left:after {
    /*border-color: rgba(136, 183, 213, 0);*/
    border-right-color: #7a359e;
    border-width: 8px;
    margin-top: -8px;
}

.purpleBg-dropdown.purpleBg-left:before {
    /*border-color: rgba(194, 225, 245, 0);*/
    border-right-color: #7a359e;
    border-width: 8px;
    margin-top: -8px;
}


.purpleBg-dropdown.purpleBg-right:after, .purpleBg-dropdown.purpleBg-right:before {
    left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.purpleBg-dropdown.purpleBg-right:after {
  /*border-color: rgba(136, 183, 213, 0);*/
  border-left-color: #7a359e;
  border-width: 8px;
  margin-top: -8px;
}

.purpleBg-dropdown.purpleBg-right:before {
  /*border-color: rgba(194, 225, 245, 0);*/
  border-left-color: #7a359e;
  border-width: 8px;
  margin-top: -8px;
}



.note-header {
    color: #ffffff;
    font-size: 15px;
    font-family: 'karlaBold';
    padding-bottom: 10px;
    text-transform: uppercase;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.notes-block ul {
    margin-bottom: 0px;
}

.notes-block ul li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 15px 0px 15px 0px;
}

.notes-block .media-body p {
    font-size: 14px;
    font-family: "karlaRegular";
    line-height: 17px;
    color: rgba(255, 255, 255, 0.65);
    margin-bottom: 15px;
    font-weight: normal;
}

.notes-block .note-add-modify {
    font-size: 13px;
    font-family: 'karlaRegular';
    color: #ffffff;
}

.notes-block .note-add-modify a:hover {
    color: #22b970;
}

.note-popup {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0px;
    width: 19px;
    height: 20px;
    background: url("../images/edit-note.png") no-repeat 0px;
}

.note-popup:hover, .note-popup:focus, .note-popup:active {
    background: url("../images/active-edit-note.png") no-repeat 0px;
}

html button:focus {
    outline: 0;
}

/*usm card*/

.usm-cards .col-md-3 {
    margin-bottom: 10px;
}

.usm-cards .card {
    /*max-width:234px;*/
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    height: 100%;
}

.usm-cards .card .card-body {
    padding: 0px;
}

.usm-cards .cardImg-section {
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
    /*padding-top:5px;*/
}

.usm-cards .cardImg-section .card-image {
    min-height: 92px;
    /*max-height: 132px;*/
    position: relative;
}

.usm-cards .cardImg-section .card-image img.agent {
    width: 100%;
}

.usm-cards .cardImg-section .card-image img.club {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.usm-cards .cardImg-section .cardTitle-section {
    background: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    padding: 14px 5px;
    font-family: 'karlaBold';
    line-height: normal;
    min-height: 66px;
}

.usm-cards .cardImg-section .cardTitle-section .card-title {
    margin-bottom: 0px;
    font-size: 15px;
    text-transform: uppercase;
}

.usm-cards .cardImg-section .cardTitle-section .card-subtitle {
    font-size: 14px;
    margin-top: 3px;
}

.usm-cards .card-head-dropdown {
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.4);
    padding: 5px 17px;
    padding-top: 8px;
    padding-right: 12px;
}

.usm-cards .card-head-dropdown .card-dropdown {
    color: #ffffff;
    font-family: 'karlaBold';
    font-size: 15px;
    text-transform: uppercase;
    padding-left: 30px;
    border: none;
    background: url("../images/active-user-icon.png") no-repeat 0px;
}

.usm-cards .card-head-dropdown .dropright .dropdown-toggle:after {
    display: none;
}

.usm-cards .card-head-dropdown.disbaled .card-dropdown {
    color: rgba(255, 255, 255, 0.28);
    background: url("../images/user-icon.png") no-repeat 0px;
}

.usm-cards .card-data {
    text-align: left;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    font-family: 'karlaRegular';
    padding: 17px 17px 10px 17px;
    /*padding-bottom:11px;*/
}

.usm-cards .card-data .card-data-label {
    color: rgba(255, 255, 255, 0.6);
}

.usm-cards .card-data .card-data-content {
    margin-bottom: 10px;
    font-family: 'karlaBold';
}

.usm-cards .cardContent-section {
    /*background:  rgba(0,0,0,0.2);*/
    padding: 18px;
    text-align: center;
}

.usm-cards .cardContent-section .card-text {
    font-size: 13px;
    font-family: 'karlaRegular';
    color: #ffffff;
}

.usm-cards .cardContent-section .card-alert {
    font-size: 11px;
    font-weight: normal;
    font-style: italic;
    font-family: "karlaItalic";
    font-stretch: normal;
    letter-spacing: 0.1px;
    text-align: left;
    color: #f9f9f9;
    text-align: center;
    opacity: 0.3;
    margin-top: 16px;
}

/*button-stylesheet*/

.btn-usmPrimary {
    min-height: 28px;
    min-width: 117px;
    border-radius: 15px;
    font-size: 14px;
    font-family: 'karlaBold';
    padding: 3px 15px;
    text-transform: uppercase;
    color: #ffffff;
    background: #d42562;
    /* Old browsers */
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #d42562 0%, #f16767 63%, #f16767 63%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#d42562), color-stop(63%, #f16767), color-stop(63%, #f16767));
    background: -o-linear-gradient(left, #d42562 0%, #f16767 63%, #f16767 63%);
    background: linear-gradient(to right, #d42562 0%, #f16767 63%, #f16767 63%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d42562', endColorstr='#f16767', GradientType=1);
    /* IE6-9 */
}

.btn-trans, .btn-transWhite {
    /*min-width: 88px;*/
    background: rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-size: 12px;
    font-family: 'karlaRegular';
    border-radius: 15px;
    padding: 2px 10px;
}

.btn-transWhite {
    background: rgba(250, 250, 250, 0.2);
    padding: 3px 10px;
    font-size: 14px;
}

.small-button {
    min-width: 115px;
    min-height: 23px;
    font-size: 12px;
    font-family: 'karlaBold';
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    border: none;
    cursor: pointer;
    padding: 3px 10px;
}

.xs-small-btn {
    min-width: 72px;
    font-size: 13px;
    font-family: 'karlaBold';
    color: #ffffff;
    border: none;
    padding: 0px 10px;
    border-radius: 15px;
}

.default-btn {
    text-transform: uppercase;
    font-size: 13px;
    font-family: 'karlaBold';
    /* min-height: 38px; */
    margin: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-width: 201px;
    border: none;
    cursor: pointer;
    text-align: center;
    /* line-height: 38px;
    padding-left: 20px;
    padding-right: 20px; */
    padding: 12px 20px;
}

.default-btn.width-auto {
    min-width: auto;
    padding-left: 24px;
    padding-right: 24px;
}

.form-chips {
    min-width: 74px;
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    font-size: 11px;
    font-family: 'karlaRegular';
    font-weight: normal;
    border-radius: 15px;
    padding: 4px 3px 4px 10px;
    text-align: left;
}

/*USM main container*/

#mainContainer {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 100%;
}

/*USM DRAWER*/

.usmDrawer {
    -webkit-transition: margin-right .5s;
    -o-transition: margin-right .5s;
    transition: margin-right .5s;
    z-index: 2;
    margin-right:-6px;
}



.toggled .usmDrawer img {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}


#drawerOpen {
    /* z-index: ; */
    position: absolute;
    right: 0px;
    top: 0px;
    width: 0;
    height: 100%;
    /* overflow-y: auto;
    overflow-x: hidden;   */
    overflow: inherit;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    min-height: 1300px;
}

#drawerOpen .drawerInner {
    width: 100%;
    display: none;  
}
     
.toggled #drawerOpen .drawerInner{
    display: block;
 }

#drawerOpen .drawerInner .dw-user-block {
    height: 217px;
    position: relative;
    text-align: center;
    margin-top: 112px;
    border-top: 3px solid rgba(255, 255, 255, 0.2);
    border-bottom: 3px solid rgba(255, 255, 255, 0.2);
}

#drawerOpen .drawerInner .dw-user-block .user-img-select {
    width: 17px;
    height: 15px;
    right: 10px;
    top: 10px;
    position: absolute;
    z-index: 1;
    padding: 0;
}

#drawerOpen .drawerInner h5 {
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'karlaBold';
    margin-bottom: 0px;
    color: #ffffff;
}

#drawerOpen .drawerInner .dw-user-detail {
    padding: 12px 12px 0px 12px;
    text-align: center;
    border-bottom: 3px solid rgba(255, 255, 255, 0.2);
}

#drawerOpen .drawerInner .dw-user-detail .dw-select-block {
    border-top: 3px solid rgba(255, 255, 255, 0.2);
    border-bottom: 3px solid rgba(255, 255, 255, 0.2);
    padding: 5px 0px;
    margin-top: 20px;
}

#drawerOpen .drawerInner .dw-user-detail .dw-select-block label {
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
    width: 100%;
}

#drawerOpen .drawerInner .dw-user-detail .dw-select-block select {
    height: 31px;
    border-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.6);
}

#drawerOpen .drawerInner .dw-user-detail .dw-user-contact-detail {
    text-align: left;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 15px;
    margin-top: 18px;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    font-family: 'karlaRegular';
}

#drawerOpen .drawerInner .dw-user-detail .dw-user-contact-detail .user-label {
    color: rgba(255, 255, 255, 0.6);
}

#drawerOpen .drawerInner .dw-user-detail .dw-user-contact-detail .label-content {
    margin-bottom: 18px;
    word-break: break-word;
    /* word-break: break-all; */
}

#drawerOpen .drawerInner .dw-note-detail {
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 13px;
    line-height: normal;
    font-family: 'karlaRegular';
    text-align: left;
}

#drawerOpen .drawerInner .dw-note-detail h5 {
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 8px;
}

#drawerOpen .drawerInner .dw-note-detail h6 {
    color: rgba(255, 255, 255, 0.32);
    margin-bottom: 8px;
    font-size: 12px;
}
#drawerOpen .drawerInner .dw-note-detail h6 span{
    width:100%;
    display: block;
}

#drawerOpen .drawerInner .dw-note-detail p {
    color: rgba(255, 255, 255, 0.65);
    margin-bottom: 0px;
}

#drawerOpen .drawerInner .dw-note-detail a:hover {
    text-decoration: none;
    color: #22b970;
    font-family: 'karlaBold';
}

#drawerOpen .drawerInner .dw-user-heading {
    font-size: 16px;
    line-height: 32px;
    text-transform: uppercase;
    border-bottom: 2px solid #ffffff;
    padding-bottom:5px;
}

#drawerOpen .drawerInner .dw-notification .dw-notification-content {
    color: rgba(255, 255, 255, 0.65);
    font-family: 'karlaRegular';
    font-size: 13px;
    margin-top: 10px;
}

#drawerOpen .drawerInner .dw-notification .dw-notification-content img {
    margin: 0px 7px 0px 10px;
    vertical-align: middle;
}

#drawerOpen .drawerInner .dw-notification .dw-notification-content span {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.35);
    margin-left: 5px;
}

#drawerOpen .drawerInner .dw-user-subheading {
    font-size: 13px;
    text-transform: uppercase;
    margin-top: 20px;
    font-family: 'karlaRegular';
}

#drawerOpen .drawerInner .dw-user-sub-detail {
    padding: 36px 15px;
    padding-top: 30px;
    color: #ffffff;
    font-family: 'karlaRegular';
}

#drawerOpen .drawerInner .dw-user-sub-detail p {
    margin: 13px 0px 20px 0px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    line-height: 17px;
}

#drawerOpen .drawerInner .dw-user-permission {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px;
    color: #ffffff;
    font-family: 'karlaBold';
}

#drawerOpen .drawerInner .dw-user-permission h6 {
    font-size: 14px;
    margin: 0px;
}

#drawerOpen .drawerInner .dw-user-permission p {
    font-size: 13px;
    margin: 0px;
}

#drawerOpen .drawerInner .dw-user-action {
    padding: 20px 15px 20px 15px;
}

#drawerOpen::-webkit-scrollbar {
    display: none;
}

#mainContainer.toggled #drawerOpen {
    width: 262px;
    border-left: 5px solid rgba(0, 0, 0, 0.44);
    visibility: inherit;
}

#mainContainer.toggled #mainContainer {
    position: absolute;
    margin-right: -262px;
}

#mainContainer.toggled #mainContainer {
    position: absolute;
    margin-left: -262px;
}

#mainContainer.toggled {
    padding-right: 302px;
}

#mainContainer.toggled .usmDrawer {
    margin-right: -69px;
    z-index: 2;
}

/*usm-table*/

.usm-table {
    height: 100%;
}

.usm-table .table-heading {
    font-size: 16px;
    font-family: 'karlaRegular';
    color: #ffffff;
    padding: 23px 0px;
    padding-top: 0px;
}

.usm-table table {
    width: 100%;
    color: #ffffff;
    line-height: normal;
}

.usm-table table .usm-thead th {
    color: #ffffff;
    background-color: rgba(3, 3, 3, 0.5);
    border-color: rgba(0, 0, 0, 0.2);
    font-family: 'karlaBold';
    padding: 10px 18px;
    font-size: 14px;
}

.usm-table table tr td {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    padding: 20px 18px;
    font-size: 14px;
    font-family: 'karlaRegular';
}

.usm-table table tr td a {
    color: #ffffff;
    vertical-align: top;
}

.usm-table table tr td a:hover {
    text-decoration: none;
}

.usm-table table tr td span.table-clubImg {
    margin-right: 5px;
    display: inline-block
}

span.circular-img img,
.usm-table table tr td span.table-clubImg img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border: 3px solid rgba(255, 255, 255, 0.2);
}

.usm-table table .table-btn {
    min-height: 16px;
    font-size: 12px;
    font-family: 'karlaBold';
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    border: none;
    padding: 1px 14px;
    border-radius: 15px;
}

.usm-table table.lastTwo-center .usm-thead th:nth-last-child(-n+2) {
    text-align: center;
}

.usm-table table.lastTwo-center tr td:nth-last-child(-n+2) {
    text-align: center;
}

.usm-table table.lastFour-center .usm-thead th:nth-last-child(-n+4) {
    text-align: center;
}

.usm-table table.lastFour-center tr td:nth-last-child(-n+4) {
    text-align: center;
}

.usm-table table.lastOne-center .usm-thead th:nth-last-child(-n+1) {
    text-align: center;
}

.usm-table table.lastOne-center tr td:nth-last-child(-n+1) {
    text-align: center;
}

.usm-table table.lastThree-center .usm-thead th:nth-last-child(-n+3) {
    text-align: center;
}

.usm-table table.lastThree-center tr td:nth-last-child(-n+3) {
    text-align: center;
}

.popover {
    min-width: 258px;
    width: 100%;
    background-color: #7a359e;
    border: none;
    -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.5);
}

.popover .arrow:after {
    border-left-color: #7a359e;
    border-right-color: #7a359e;
}

.popover .popover-body {
    padding: 10px 18px;
}

.usm-table-popover .table-popover-heading {
    font-size: 15px;
    font-family: 'karlaBold';
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 5px;
}

.usm-table-popover .table-popover-heading span {
    font-size: 36px;
    font-family: 'karlaRegular';
    margin-right: 3px;
}

.usm-table-popover-scroll {
    padding-right: 20px;
}

.usm-table-popover table {
    color: #ffffff;
    width: 100%;
}

.usm-table-popover table th {
    font-size: 14px;
    font-family: 'karlaBold';
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding: 5px 0px;
}

.usm-table-popover table th:last-child {
    text-align: right;
}

.usm-table-popover table td {
    font-size: 13px;
    font-family: 'karlaRegular';
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0px;
}

.usm-table-popover table td:last-child {
    font-family: 'karlaBold';
    text-align: right;
    text-transform: uppercase;
}

.usm-table-popover table tbody tr:last-child td {
    border-bottom: none;
}

.usm-tabs {
    background: rgba(0, 0, 0, 0.44);
    min-height: 46px;
    margin-bottom: 30px;
}

.usm-tabs ul {
    min-height: 46px;
}

.usm-tabs ul li.nav-item {
    border-right: 1px solid #40135f;
    padding: 0px;
}

.usm-tabs ul li.nav-item:last-child {
    border-right: none;
    padding-right: 15px;
}

.usm-tabs ul li.nav-item:first-child {
    padding-left: 15px;
}

.usm-tabs .nav-link {
    text-align: center;
    font-family: 'karlaBold';
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    padding: 14px 15px;
    height: 100%;
}

.player-detail-block {
    background: rgba(3, 3, 3, 0.1);
    height: 100%;
}

.player-detail-block .player-detail-heading {
    min-height: 69px;
    padding: 18px;
    background: #280a35;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'karlaRegular';
}

.player-detail-block .player-detail-content {
    padding: 18px;
}

.player-detail-block .player-detail-content .nc-nb-heading {
    color: #ffffff;
    font-size: 14px;
    font-family: 'karlaBold';
    margin-bottom: 5px;
}

.player-detail-block .player-detail-content .notes-block li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.player-detail-block .player-detail-content .notes-block li:last-child {
    border-bottom: none;
}

.player-detail-block .player-detail-content .career-ul {
    padding: 18px 0px;
    color: #ffffff;
    font-size: 14px;
    font-family: 'karlaBold';
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.player-detail-block .player-detail-content .career-ul img {
    margin-right: 10px;
    vertical-align: middle;
}

.top-browse-btn {
    font-size: 14px;
    font-family: 'karlaBold';
    text-transform: uppercase;
    color: #ffffff;
}

.top-browse-btn:hover {
    text-decoration: none;
    color: #ffffff;
}

.top-browse-btn img {
    margin-right: 8px;
}

.added-by-text {
    font-size: 13px;
    font-family: 'karlaRegular';
    color: rgba(255, 255, 255, 0.5);
}

.added-by-text span {
    font-family: 'karlaBold';
    font-size: 14px;
    margin-left: 6px;
}

.form-top-buttons {
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.usm-alert {
    color: #ffffff;
    position: absolute;
    width: 100%;
    z-index: 9;
    left: 0px;
    top: 0px;
    font-size: 16px;
    line-height: 20px;
    font-family: 'karlaRegular';
    padding: 18px 22px;
    padding-right: 30px;
}

.usm-alert .close {
    opacity: 10;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.input-delete-box {
    display: block;
    width: 100%;
    height: 38px;
    font-size: 14px;
    line-height: 1.7;
    font-family: 'karlaRegular';
    color: #fff;
    margin-top: 18px;
    border-radius: none;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
}
.input-delete-box button{
    position: absolute;
    right: 0px;
    top: 5px;
}
.input-delete-box input{
    border-color: rgba(255, 255, 255, 0.2);
}

/*video block start here*/

.video-block {
    max-width: 280px;
    max-height: 158px;
    background-size: cover;
    position: relative;
    padding-top: 56.25%;
}

.video-block img {
    width: 100%;
}

.video-block .video-play {
    position: absolute;
    z-index: 1;
    max-width: 74px;
    max-height: 74px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/*commom css*/

.border-trans {
    border-color: rgba(0, 0, 0, 0.2) !important;
}

.border-bottom-trans {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.border-top-trans {
    border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.border-left-trans {
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-right-trans {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.marginLR0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.paddingL0 {
    padding-left: 0px !important;
}

.marginT25 {
    margin-top: 25px;
}

.marginB30 {
    margin-bottom: 30px;
}

.marginB10 {
    margin-bottom: 10px !important;
}

.text-align-center {
    text-align: center;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.text-transform-normal {
    text-transform: none!important;
}

.font-size24 {
    font-size: 24px !important;
}

.font-size20 {
    font-size: 20px !important;
}

.font-size18 {
    font-size: 18px !important;
}

.font-size16 {
    font-size: 16px !important;
}

.font-size14 {
    font-size: 14px !important;
}

.font-size13 {
    font-size: 13px !important;
}

.font-size12 {
    font-size: 12px !important;
}

.fontBold {
    font-family: "karlaBold" !important;
}

.fontNormal {
    font-family: "karlaRegular" !important;
}

.line-height-normal {
    line-height: normal !important;
}

.marginB5 {
    margin-bottom: 5px !important;
}

.marginB3 {
    margin-bottom: 3px !important;
}

.marginB18 {
    margin-bottom: 18px;
}

.marginB20 {
    margin-bottom: 20px !important;
}

.marginB25 {
    margin-bottom: 25px;
}

.marginB60 {
    margin-bottom: 60px;
}

.paddingT5 {
    padding-top: 5px;
}

.padding0 {
    padding: 0px !important;
}

.paddingL30 {
    padding-left: 30px;
}

.paddingR30 {
    padding-right: 30px;
}

.paddingB10 {
    padding-bottom: 10px !important;
}

.paddingB40 {
    padding-bottom: 40px !important;
}

.paddingT40 {
    padding-top: 40px !important;
}

.paddingT70 {
    padding-top: 70px !important;
}

.paddingB20 {
    padding-bottom: 20px !important;
}

.text-purpleLight {
    color: #8b6f9e !important;
}

.marginT82 {
    margin-top: 50px;
}

.marginT15 {
    margin-top: 15px;
}

.marginT14 {
    margin-top: 14%;
}

.marginB0 {
    margin-bottom: 0px !important;
}

.marginL10 {
    margin-left: 10px !important;
}

.marginR5 {
    margin-right: 5px !important;
}

.marginR10 {
    margin-right: 10px !important;
}

.marginL18 {
    margin-left: 18px;
}

.borderRadius15 {
    border-radius: 15px !important;
}

.text-offWhite {
    color: rgba(255, 255, 255, 0.4) !important;
    /*#e5e9ed*/
}

.font-white {
    color: #ffffff !important;
}

.padding20 {
    padding: 20px;
}

.text-opt-four {
    color: rgba(255, 255, 255, 0.4) !important;
}

.right0 {
    right: 0px !important;
}

.marginB35 {
    margin-bottom: 35px;
}

.marginB15 {
    margin-bottom: 15px;
}

.paddingT0 {
    padding-top: 0px !important;
}

.marginT0 {
    margin-top: 0px !important;
}

.marginT5 {
    margin-top: 5px !important;
}

.marginT30 {
    margin-top: 30px !important;
}

.marginT35 {
    margin-top: 35px !important;
}

.marginT10 {
    margin-top: 10px !important;
}

.marginT15 {
    margin-top: 15px !important;
}

.marginT20 {
    margin-top: 20px !important;
}

.marginT34 {
    margin-top: 34px;
}

.marginTB34 {
    margin-top: 34px;
    margin-bottom: 34px;
}

.checkbox-linetwo {
    padding-left: 41px;
    position: relative;
    top: -5px;
}

.min-width157 {
    min-width: 157px !important;
    padding-left: 20px;
    padding-right: 20px;
}

.input-height30 {
    height: 30px !important;
}

.auto-height {
    height: auto !important;
    min-height: auto !important;
}

.disabled, :disabled {
    cursor: not-allowed !important;
    opacity: 0.7;
}
.disable_not_allowed_cursor .disabled, 
.disable_not_allowed_cursor :disabled {
    cursor: default !important;
}

.textarea76 {
    height: 76px !important;
    width: 100%;
}

.textarea90 {
    height: 90px !important;
    width: 100%;
}

.tags-chip .bootstrap-tagsinput {
    height: 192px;
}

.background-none {
    background: none !important;
}

[data-toggle=popover]:hover, [data-toggle=popover]:focus {
    color: #22b970;
}

.form-subheading {
    font-size: 15px;
    font-family: 'karlaBold';
    text-transform: uppercase;
    padding: 17px 0px 10px 0px;
    color: #ffffff;
}

.border-white {
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-top3x {
    border-top: 3px solid rgba(255, 255, 255, 0.2) !important;
}

.border-bottom3x {
    border-bottom: 3px solid rgba(255, 255, 255, 0.2) !important;
}

.paddingTB12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.paddingTB35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.paddingT35 {
    padding-top: 35px;
}

.marginTB35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.modal-backdrop.show {
    opacity: 0.9;
}

.calendar-icon {
    background: url("../images/calendar.png") no-repeat 98.5% center;
    /*	cursor: pointer;*/
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none !important;
    color: inherit;
}

.form-group p {
    color: #ffffff;
    margin-bottom: 0px;
    line-height: 1;
}

.form-group.form-filled label {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.arrow-right {
    position: absolute;
    display: block;
    left: 100%;
    top: 50%;
    right: 0px;
    width: 0.5rem;
    height: 1rem;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.arrow-right:after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-left-color: #7a359e;
    border-right-color: #7a359e;
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

/*Css updates*/

.usm-dropdown-style {
    background: #7a359e;
}

.usm-dropdown-style .dropdown-item {
    color: #ffffff;
}

.usm-dropdown-style .dropdown-item:hover, .usm-dropdown-style .dropdown-item:focus {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.2);
}

select, input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
}

select::-ms-expand {
    display: none;
}

select>option {
    background: #7a359e;
    color: #ffffff;
    font-family: 'karlaRegular';
    line-height: 30px;
}

select {
    background: url(../images/select-arrow.png) no-repeat right center transparent;
}

/*Added by Gaurav*/

.usm-modal-active {
    display: block;
}

.form-control:disabled, button[disabled], .form-control[readonly] {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
}

.usm-modal .usm-modal-body p {
    text-align: justify;
    word-break: normal;
    -ms-word-break: normal;
}

.modal-active {
    display: block;
}

.user-dropdown-icon, button.modal-close, button.logout-btn, .transparent-btn, button[data-target="#addUserModal"] {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.text-upper {
    text-transform: uppercase;
}

.usm-modal .usm-modal-body .form-group .display-error ul {
    margin-bottom: 0px;
}

.zIndex3 {
    z-index: 3 !important;
}

.form-modal .modal-dialog .modal-content {
    min-height: auto;
}

.minHeight-auto {
    min-height: auto !important;
}

.add-photo-section .upload-image img {
    max-width: 122px;
    max-height: 123px;
    float: right;
}

.alert.alert-float {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 0px;
    bottom: auto;
    display: block;
    z-index: 9999;
}

.change-password {
    display: none;
}

.change-password.active {
    display: block;
}

.modal-backdrop.show {
    opacity: 0.9;
}

a:hover {
    text-decoration: none !important;
    color: inherit;
}

.usm-alert {
    color: #ffffff;
    position: absolute;
    width: 100%;
    z-index: 9;
    left: 0px;
    top: 0px;
    font-size: 16px;
    line-height: 20px;
    font-family: 'karlaRegular';
    padding: 18px 22px;
    padding-right: 30px;
}

.usm-alert .close {
    opacity: 10;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.intl-tel-input .selected-flag .iti-arrow.up {
    border-top: 4px solid #ffffff !important;
    border-bottom: none !important;
}

.intl-tel-input .selected-flag .iti-arrow {
    border-bottom: 4px solid #ffffff !important;
    border-top: none !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    color: white;
    font-size: 13px;
    font-family: 'karlaRegular';
}

.react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
}

.react-datepicker-wrapper {
    width: 100%;
}

.width-10 {
    width: 10%;
}

.width-15 {
    width: 15%;
}

.width-20 {
    width: 20%;
}

.width-25 {
    width: 25%;
}

.width-30 {
    width: 30%;
}

.club-slider-btn {
    padding: 0px;
    margin: 0px;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 25px;
}

.club-slider-btn i {
    font-size: 35px;
    vertical-align: bottom;
}

.row.usm-cards.club-slider-block.widget-slider>div {
    display: none;
}

.row.usm-cards.club-slider-block.widget-slider>a {
    display: none;
}

.row.usm-cards.club-slider-block.widget-slider>.visible {
    display: block;
}

.marginL0 {
    margin-left: 0px !important;
}

.dropdown-menu.purpleBg-dropdown.ai-dropdown.purpleBg-top.show.status-dropdown {
    left: auto;
    right: -73px;
    z-index: 3;
}

.form-modal .form-group.upload-pics-comp .display-error {
    top: 45px;
}

.usm-check input:checked+label.disabled:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #e36e65;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 13px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    color: #e36e65;
    cursor: not-allowed;
    margin-right: 12px;
}

.usm-check input:checked+label.disabled:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 11px;
    width: 7px;
    height: 16px;
    border: solid #e36e65;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

#drawerOpen .drawerInner .dw-user-detail .dw-select-block {
    border-top: 3px solid rgba(255, 255, 255, 0.2);
    border-bottom: 3px solid rgba(255, 255, 255, 0.2);
    padding: 12px 15px 7px 15px;
    margin-top: 20px;
    margin-left: -12px;
    margin-right: -12px;
}

/*responsive css*/

@media (max-width: 768px) {
    .widget-slider .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 600px) {
    .default-btn {
        min-width: auto!important;
    }
    .form-modal .form-modal-header {
        padding: 20px 18px;
    }
    .form-modal .form-modal-header img {
        width: 30%;
    }
    .form-modal .form-modal-header .form-modal-title {
        font-size: 18px;
    }
    .form-modal .form-modal-header .modal-close img {
        width: 67%;
    }

    #mainContainer.toggled {
        padding-right: 0px;
    }
    .drawerInner {
        background: #440f63;
    }
}

.intl-tel-input .country-list .country {
    padding: 7px 10px!important;
    font-family: 'karlaRegular';
    font-size: 13px;
}

.intl-tel-input .country-list {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    background-color: #7a359e!important;
    border: 1px solid #824e84!important;
    width: 100%;
}

.boundary-90 {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 82%;
    overflow-x: hidden;
}

.contact-caret {
    color: rgba(255, 255, 255, 0.2);
    vertical-align: middle;
    position: absolute;
    right: -14px;
    top: -5px;
    font-size: 27px;
}

.min-width155 {
    min-width: 155px;
}

.min-width155 a {
    display: inline-block;
}

.min-width160 {
    min-width: 160px;
}

.min-width160 a {
    display: inline-block;
}

.players-considered {
    padding: 0;
}

.players-considered input {
    border: none;
    width: 100% !important;
    background: transparent;
    outline: none;
    color: #ffffff;
    line-height: 1;
    padding: 10px;
}

.react-tags {
    position: relative;
}

.react-tags__selected-tag {
    /* min-width: 74px; */
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    font-size: 11px;
    font-family: "karlaRegular";
    font-weight: normal;
    border-radius: 15px;
    padding: 4px 30px 4px 15px;
    text-align: left;
    border: none;
    margin-bottom: 15px;
    margin-right: 13px;
}

.react-tags__suggestions {
    background: rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-size: 13px;
    font-family: "karlaRegular";
    position: relative;
    z-index: 2;
}

.react-tags__suggestions ul {
    list-style-type: none;
    margin-left: 0px;
    padding-left: 0px;
    width: 100%;
}

.react-tags__suggestions ul li {
    padding: 8px 10px;
    margin-left: 0px;
}

.react-tags__suggestions ul li:hover {
    background: rgba(255, 255, 255, 0.2);
}

.css-vj8t7z, .css-2o5izw, .css-162g8z5 {
    width: 100%;
    /* padding: 0.375rem 0.75rem; */
    font-size: 13px;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2)!important;
    background-clip: padding-box;
    border: 1px solid #824e84!important;
    border-radius: 0px!important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    /* max-height: 38px!important; */
}
.css-162g8z5{
    background-color: rgba(0, 0, 0, 0.1)!important;
}
.css-162g8z5 .css-ln5n5c{
    display: none;
}
.pre-search {
    background: #7a359e;
    color: #ffffff;
    width: 100%;
    margin-top: 3px;
    padding-left: 0px;
    list-style: none;
    position: absolute;
    z-index: 5;
    margin-bottom: 0;
}

.pre-search li {
    padding: 7px 15px;
    font-size: 13px;
}

.pre-search li:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.css-15k3avv {
    background: #7a359e!important;
    color: #ffffff;
    border-radius: 0px!important;
    border: none!important;
    position: absolute;
    z-index: 2!important;
    top: 33px!important;
}

.css-15k3avv ul li {
    padding: 10px 15px;
}

.css-15k3avv ul li label:hover {
    background: rgba(0, 0, 0, 0.2)!important;
}

.css-d8oujb {
    display: none;
}

.css-xwjg1b {
    min-width: 74px;
    background: rgba(255, 255, 255, 0.2)!important;
    border-radius: 15px!important;
    padding: 0px 3px 0px 6px;
    text-align: left;
}


.css-12jo7m5 {
    color: #ffffff!important;
    font-size: 11px;
    font-weight: normal;
    font-family: 'karlaRegular';
}

.css-1hwfws3 {
    padding: 0px!important;
}

.css-1alnv5e {
    background-color: rgba(255, 255, 255, 0.2)!important;
    border-radius: 50%!important;
    margin-left: 8px;
    width: 20px;
    height: 18px;
    margin-top: 2px;
    cursor: pointer;
}

.css-1alnv5e:hover {
    background-color: rgba(0, 0, 0, 0.2)!important;
    color: #ffffff!important;
}

.css-10nd86i {
    background: url("../images/select-arrow.png") no-repeat right center;
    /* background: none; */
}

.css-1ep9fjw svg path, .css-1uq0kb5 svg path, .css-vj8t72 svg path {
    display: none;
    height: 0px;
    width: 0px;
}

.css-10nd86i svg {
    height: 0px;
    width: 0px;
}

.css-1hwfws3 {
    margin-left: 10px;
}

.css-1hwfws3:focus, .css-10nd86i:focus, .css-1g6gooi:focus, .css-b16clz:focus, .css-2o5izw:focus {
    border: none!important;
    outline: 0px!important;
}

.css-xp4uvy {
    color: #ffffff!important;
}

.css-1g6gooi input {
    color: #ffffff !important;
}

.css-xwjg1b .css-1alnv5e svg{
  width:14px;
  height: 14px;
}
.player-tags .react-tags__selected {
    position: relative;
    top: 0;
}

.show-rollover {
    cursor: pointer;
    z-index:99;
}

.show-rollover .popover-content-toggle-one {
    display: none;
}

.show-rollover:hover .popover-content-toggle-one {
    display: block !important;
}

div.popover-content-toggle-one {
    position: absolute;
    top: 50%;
    left: -265px;
    right: auto;
    bottom: auto;
    z-index: 9;
    padding: 20px;
    padding-right: 0px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-bottom: 10px;
}

.popover-content-toggle-one {
    width: 258px;
    background-color: #7a359e;
    border: none;
    -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.5);
}

.usm-table-popover {
    text-align: left;
}

.arrow-right {
    position: absolute;
    display: block;
    left: 100%;
    top: 50%;
    right: 0px;
    width: 0.5rem;
    height: 1rem;
    -webkit-transform: translate(-0%, -50%);
    -ms-transform: translate(-0%, -50%);
    transform: translate(-0%, -50%);
}

.arrow-right:after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-left-color: #7a359e;
    border-right-color: #7a359e;
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

@media (min-width: 769px) {

    .col-md-1.outcomCol{
        max-width: 10%;
        flex: 10%; 
        margin-top:8px; 
    }
    .note-add-modify .col-md-2.width10{
        max-width: 10%;
        flex: 10%; 
    }
}

.datepicker-wrap .react-datepicker-popper {
    z-index: 10;
}

/* .usm-check label[for="endingnotification"]::before {
  padding: 17px;
  vertical-align: top;
  float: left;
}

.usm-check input:checked+label[for="endingnotification"]::after {
  top: 46%;
  left: 15px;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
} */

.form-modal .form-group .display-error.custome-error-top44 {
    top: 44px;
    z-index: 999;
}

.form-modal .form-group .display-error.custome-error-top108 {
    top: 108px;
    z-index: 9;
}

select:focus::-ms-value {
    background: transparent!important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .navbar-collapse .usm-form {
        width: 300px;
    }
}

.notes-block .restricted{
    margin-left: 50px;
}

.selected-flag{
  z-index:1;
}

.intl-tel-input .country-list{
  overflow-x:hidden;
}

.player-add-note{
    top: -21px;
    right: 0px;
    left: 42px;
}

.player-add-concierge{
    top: -8px;
}

.player-add-note .dropdown-item{
    line-height: 20px;
    padding-left: 20px;
}

.player-add-note .dropdown-item:hover{
    background: transparent;
}

.dw-note-detail-hover .dw-note-detail-hover-block{
    display:none;
    width: 100%;
    min-width: 500px;
    left: 0px!important;
    top: 19% !important;
    left: -525px !important;
}
.dw-note-detail-hover:hover .dw-note-detail-hover-block{
    display:block;
}

.dw-user-block img.marginT14, .dw-user-block .upload-image img{
  height:157px;
}
.player-img-wrap {
    max-width: 100%;
    padding: 10px;
}
#drawerOpen .drawerInner .dw-user-block .player-img-wrap img {
    max-width: inherit;
}
.usm-loading {
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    bottom: 0;
}
.usm-loading img {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    height: 100px;
}
.usm-countdown {
	position: fixed;
	top: auto;
	bottom: 30px;
	left: 10px;
	padding: 10px;
	z-index: 1111;
}
.usm-countdown label, 
.usm-countdown span {
    vertical-align: middle;
    margin: 0;
}
.z-index9999{
    z-index:9999;
}
.z-index99999{
    z-index:99999;
}
.word-break-all{
    word-break: break-all;
}

.width12{
    width:12%;
}
.marTN6{
    margin-top:-6px;
}
.form-modal .react-datepicker__month-read-view--down-arrow,
.form-modal .react-datepicker__year-read-view--down-arrow {
    top: 5px;
}
.usm-report .media-body,
.notes-block .usm-report .media-body p {
    max-width: 90%;
}
.player-detail-block .player-detail-content .nc-nb-heading p {
    font-family: 'karlaBold';
    margin-bottom: 5px;
}
.card-image img.club {
    max-width: 60px;
}
.paddingR15 {
    padding-right: 15px;
}
span.table-player-img img {
    padding: 0 10px;
}
img.search-loader {
    position: absolute;
    top: -5px;
    width: 35px;
    height: 35px;
    right: 13px;
    z-index: 2;
}
.form-group img.search-loader {
    top: 50%;
    transform: translateY(-15%);
    right: 0px;
}
.card-image img.case {
    max-width: 85px;   
}
ul.usm-tags-list {
    padding: 0;
}
.usm-tags-list > li {
    display: inline-block;
    list-style: none;
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    font-size: 13px;
    font-family: "karlaRegular";
    font-weight: normal;
    border-radius: 15px;
    padding: 4px 18px 4px 18px;
    text-align: left;
    border: none;
    margin-right: 13px;
}
.form-modal .form-modal-header .usm-note-sent-title.form-modal-title{
    margin-left: 0px;
}

/****Date 4-02-2019****/
* p,
.drawerInner h5,
.nc-nb-heading{
    word-break:break-word;
	/* word-break:break-all; */
}

.global-search-block{
	max-width:329px;
}

.img-same-height .usm-cards .cardImg-section .card-image{
	min-height:160px;
}

.usm-player-video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
}
div.popover-content-toggle-one.player-rollover {
    width: 275px;
    left: -285px;
}
.paddingR0 {
    padding-right: 0 !important;
}
.usm-roll-name {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.paddingT20 {
    padding-top: 20px;
}
.global-search-block .input-range__slider,
.global-search-block .input-range__track--active {
    background: #22b970;
}
.global-search-block .input-range__slider {
    border: 1px solid #22b970;
}
.global-search-block .input-range__label {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
}
.global-search-block .input-range__label--min .input-range__label-container {
    left: 0;
}
.global-search-block .input-range__label--max .input-range__label-container {
    left: auto;
    right: 0;
}
.global-search-block .input-range__label-container {
    font-size: 10px;
}
.global-search-block .input-range__slider-container:nth-child(2) .input-range__label-container {
    left: -30px;
}
.global-search-block .input-range__slider-container:nth-child(3) .input-range__label-container {
    left: 0px;
}
.global-search-block .height-ranger .input-range__slider-container:nth-child(3) .input-range__label-container {
    left: 5px;
}
span.usm-searched-options {
    font-size: 14px;
    display: inline-block;
    padding: 10px;
    text-align: center;
    overflow: hidden;
    margin: 5px;
    background: rgba(0,0,0,0.2);
    border-radius: 10px;
    text-transform: none;
    vertical-align: middle;
}
.minHeight145 {
    min-height: 145px;
}
.minHeight177 {
    min-height: 177px !important;
}
.marginL5 {
    margin-left: 5px;
}
/* .marginT5 {
    margin-left: 5px;
} */
.lh20 {
    line-height: 20px;
}
.testAlignR{
    text-align:right;
}
.disableClass .css-142w6gm{
    color:#fff; 
}

.minHeight177 + .cardTitle-section{min-height: 66px;}



.agent-section .caseTypeSelect{ margin:0; width:200px; margin-left:auto; }
.agent-section .caseTypeSelect select{border:0; height:auto; border-radius:20px; background-color:rgba(255,255,255,.2);}
.usm-agents p {
    line-height: 20px;
}
.break-word {
    word-break: break-word;
}
.break-all {
    word-break: break-all;
}
.clear-filter-btn {
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
}
.font-size30 {
    font-size: 30px !important;
}

.adminContactBlock{
    padding-left:20px; 
    padding-top: 10px;
}

.adminContactBlock img{
    margin-right:7px; 
    max-height:25px; 
}
.adminContactBlock .fontSize11{
    font-size:12px;
    color: rgba(255, 255, 255, 0.45)!important;
    margin-top:2px;   
}

.case-icon-player .cardTitle-section{
    min-height:66px; 
}

.case-icon-player .card-image img.club{
    max-width:100%;
    height:92px;  
}



.agent-section .card-image{
    min-height:110px!important;
    background-size: cover!important; 
}

#drawerOpen .drawerInner .dw-note-detail p.player-representation-date{
    padding-top: 10px;
    word-break: break-word;
}

.button-color{
    color:#22b970; 
}

.lock-back-button-color:hover, .button-color:hover{
    color:#fff; 
}

.usm-cards .cardImg-section .cardTitle-section .card-title,
.usm-cards .cardImg-section .cardTitle-section .card-subtitle {
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}

.word-break {
    word-break: break-word;
}

@media (max-width: 1024px){
    .widget-slider .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

}


@media (max-width: 600px){
    .widget-slider .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .usm-tabs .col {
        flex-basis: auto;
    }
    .usm-tabs ul li.nav-item{
        padding-left:15px;
        padding-right:15px;
    }

    .org-people-mobile .player-detail-search .apply-filtter{
        display: block!important;
        float: left!important;
        border-left: none;
        margin-top: 20px;
        padding-left: 0;
    }
    .org-people-mobile .player-detail-search .apply-filtter button{
        margin-left:10px;
    }

}

@media (max-width: 768px){
   
    #mainContainer.toggled .usmDrawer {
        right: 302px;
        position: absolute;
    }    
    #mainContainer.toggled {
        padding-right: 0px;
    }
    .drawerInner {
        background: #440f63;
    } 
    .agent-section .card-image{
        min-height:170px!important;
    }
    
  .player-detail-search .apply-filtter{
        display: block!important;
        float: left!important;
        border-left: none;
        margin-top: 20px;
        padding-left: 0;
    }
   .player-detail-search .apply-filtter button{
        margin-left:10px;
    }
}
@media (max-width: 800px){
   
    .all-filter-block .col-sm-12{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom:10px!important;
    }
    .global-search-block .global-search-result{
        width:100%;
    }
    .global-search-block .border-right-trans{
        border-right: none!important;
    }
    .usm-table-popover{
        padding:0px 10px;
    }
    .org-mobile .heading-section.d-flex{
        display: block!important;
    }
    .org-mobile .heading-section.d-flex h2{
        margin:15px 0px;
    }
    #mainContainer.toggled .content-section{ 
        z-index: -1;
         position: relative;
     }

}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .form-modal .react-datepicker__month-read-view--down-arrow, .form-modal .react-datepicker__year-read-view--down-arrow{
        left:18px;
    }
    .react-datepicker__year-read-view--selected-year{
        margin-left:20px;
    }
    .global-search-result .css-xp4uvy{
        top:18px;
        cursor: none;
    }
    .global-search-result .css-xwjg1b{
        margin-top:6px;
    }
}

input::-ms-clear {
    display: none;
}

.usm-cards .cardImg-section .card-image img.club {
    padding-top: 6px;
}

.add-video-link-in-popup{
    position: absolute;
    right: 12PX;
    top: -5px;
    z-index: 1;
}

.usm-table .infinite-scroll-component {
    overflow: inherit!important;
}
@media (min-width: 991px) and (max-width: 1024px){
    .nav-item.min-width200{
        min-width: auto;
    }
    .navbar-nav.mr-auto.usm-navbar{
        width:80%;
    }
}
@media (max-width: 991px){
    
    #drawerOpen .drawerInner .dw-note-detail-hover-block{
        display: none;
    }
    #navbarText {
        margin-top: 20px;
    }

    #navbarText .nav-item {
        padding: 4px 0px;
        margin: 0px;
    }
    .global-search-block{
        max-width:100%;
    }
        
    .usm-table .infinite-scroll-component {
        overflow: auto!important;
    }
}
@media (width: 800px){
    .usm-check input:checked+label:after{
        top:12px;
    }
}
@media (width: 768px){
    .usm-check input:checked+label:after{
        top:20px;
    }
}

.modal-body.usm-modal-body .usm-alert{
    font-size: 14px;
}   

body{
    overflow-x: hidden!important;
}

.overflow-Y-hidden{
    overflow: hidden;
}
.input-delete-box > input {
    padding-right: 2rem;
}

.heading-section .text-white.transparent-btn{
    text-transform: uppercase;
}



.widget-slider{
    position: relative;
}
.widget-arrow{
    position: absolute;
    top:44%;
    width: 100%;
    z-index: 9;
    display: block!important;
}
.widget-arrow .float-left{
    margin-left: -30px;
}
.widget-arrow .float-right{
    margin-right: -20px;
}


@media (min-width: 768px) and (max-width: 1124px) {
    .default-btn{
        min-width:auto!important;
    }
    .org-people-mobile .col-md-6.col-sm-12{
        flex: 0 0 100%;
        max-width: 100%;
    }

}


@media (max-width: 1024px) {
    .widget-slider {
        height: 380px;
        overflow: hidden;
        margin: 0px 10px;
    }
    .widget-arrow .float-left{
        margin-left: -10px;
    }
    .widget-arrow .float-right{
        margin-right: -0px;
    }
    .widget-slider .col-md-3{
        min-height: 370px;
    }

}



.usm-alert{
    padding-right:40px;
}
.usm-alert .close{
    padding:0px;
    margin-right:12px;
}
.usm-alert .close img{
    width:75%;
}
.scrollbar-div div{
    overflow-x:hidden;
    box-sizing: border-box;
}
.paddingB0{
    padding-bottom: 0px!important;
}
.paddingL15 {
    padding-left: 15px!important;
}



.browse-custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    margin: 0px;
}

.custom-file-browse {
    position: relative;
    width: 100%;
    height: calc(2.0625rem + 2px);
    margin: 0;
    opacity: 0;
    margin:0px;
}
.input-group>.custom-file-browse:not(:first-child) .browse-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.browse-label {
 
    cursor: pointer;
}

.browse-label::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #ffffff;
    content: "Browse File";
    background-color: #22b970;
    border-left: inherit;
    border-radius: none;
    text-align: center;
    width:100%;
}

.custom-file-browse:lang(en)~.browse-label::after {
    content: "Browse File";
}

.browse-label {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.usm-tag-remove {
    background: rgba(255, 255, 255, 0.3);
    text-align: center;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    right: 2px;
    border-radius: 100%;
    color: #fff;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 18px;
    font-weight: bold;
}

.break-email{
    max-width:280px;
    word-break: break-all;
}

.widget-slider .card .popover-content-toggle-one{
    left: -190px;
    width: 180px;
}
.text-white-70 {
    color: rgba(255, 255, 255, 0.7) !important;    
}
.cusror-default {
    cursor: default;
   }
   .input-delete-box > input{
       padding-right:40px;
   }

   .restricted-css{
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 0px!important;
    vertical-align: middle;
   }

   .lock-back-button-color {
    color: #ddd;
    text-decoration: underline;
}


   /*** Styles added to fix the issue with zoom in on iphone ***/
/* iPhone < 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}
